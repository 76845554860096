<script>
import demo from './Template/index'

export default {
  ...demo,
  data () {
    return {
      ...demo.data(),
      tab: 'Happy81012'
    }
  },
  computed: {
    ...demo.computed,
    content: {
      get () {
        return [
          // 广西快乐10
          this.tabLots
            .filter(item => item.id === 'GXKL10')[0],
          // 广东快乐10
          this.tabLots
            .filter(item => item.id === 'GDKL10')[0],
          // 重庆幸运农场
          this.tabLots
            .filter(item => item.id === 'CQXYNC')[0],
          // 天津快乐10
          this.tabLots
            .filter(item => item.id === 'TJKL10')[0]
          // 四川快乐12
          // this.tabLots
          //   .filter(item => item.id === 'SCKL12')[0]
        ]
      }
    }
  }
}
</script>
