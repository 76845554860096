/*
  賽車＆飛艇
  import {} from '@/utils/display/PK10'
*/

// 冠亞軍總和大小
export function PK10TotalBS (n) {
  var total = PK10Total(n)
  if (total >= 12) {
    return '大'
  }
  return '小'
}

// 冠亞軍總和
export function PK10Total (n) {
  return Number(n[0]) + Number(n[1])
}

// 龍虎
// PK10DT(n, 0), PK10DT(n, 1) = 1 vs 10, 2 vs 9 ...
export function PK10DT (n, t) {
  var firstNum = t
  var lastNum = 1 + t
  const th = Number(n[firstNum])
  const d = Number(n[n.length - lastNum])
  if (th > d) {
    return '龙'
  }
  return '虎'
}
