<template>
  <div class="num-container">
    <div
      v-for="(num, idx) in codeNums"
      :key="idx + id + num">
      {{ getNum(num) }}
    </div>
    <span v-if="plusCodeNum"> + </span>
    <div v-if="plusCodeNum">
      {{ getNum(plusCodeNum) }}
    </div>
  </div>
</template>

<script>
import { fillDigit } from '@/utils/string'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    codeNums: {
      type: Array,
      required: true
    },
    plusCodeNum: {
      type: [Number, String]
    }
  },
  methods: {
    getNum (num) {
      return fillDigit(num, 2, '0')
    }
  }
}
</script>

<style lang="scss" scoped>
.num-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  div {
    color: #FFFFFF;
    font-weight: 500;
    background-color: #2D9CDB;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 575.98px) {
  .num-container {
    // gap: 5px;
    justify-content: flex-start;
    margin: 15px 0;
    div {
      font-size: 12px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      margin: 2.5px;
    }
    span {
      font-size: 18px;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .num-container {
    // gap: 5px;
    justify-content: flex-start;
    margin: 15px 0;
    div {
      font-size: 12px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      margin: 2.5px;
    }
    span {
      font-size: 18px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .num-container {
    // gap: 10px;
    div {
      font-size: 18px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin: 5px;
    }
    span {
      font-size: 18px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .num-container {
    // gap: 10px;
    div {
      font-size: 20px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      margin: 5px;
    }
    span {
      font-size: 20px;
    }
  }
}
@media (min-width: 1200px) {
  .num-container {
    // gap: 10px;
    div {
      font-size: 20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 5px;
    }
    span {
      font-size: 20px;
    }
  }
}

</style>
