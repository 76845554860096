<template>
  <div class="hot-lots">
    <router-link
      v-for="lot in lots"
      :key="lot.lotterycode"
      class="hot-lot"
      :to="getLotTo(lot)">
      <div class="lot-logo">
        <img :src="require('@/assets/lot-logo/'+ lot.lotterycode +'.png') || ''" />
      </div>
      <h4>{{ lot.lotteryname || '' }}</h4>
    </router-link>
  </div>
</template>

<script>
import { getDisplay } from '@/api/client/display'
import resStatus from '@/constants/resStatus'

export default {
  data () {
    return {
      lots: [],
      hotLotQuery: {
        type: 'hot',
        num: '8'
      }
    }
  },
  methods: {
    getLotTo (lot) {
      return {
        name: 'LotteryChartDetail',
        query: {
          id: lot.lotterycode,
          chart: 'numAnalysis'
        }
      }
    },
    getHotLot () {
      getDisplay(this.hotLotQuery)
        .then(res => {
          if (res.status === resStatus.OK) {
            let data = res.data
            if (window.innerWidth < 330) {
              data.map((item) => {
                if (item.lotteryname.length > 6) {
                    let lotName = item.lotteryname.slice(0, -1)
                  if (lotName[lotName.length - 1] == '-'){
                    lotName = lotName.slice(0, -1)
                  }
                  item.lotteryname = lotName
                }
                return item
              })
            }
            this.lots = res.data
          }
        })
    },
  },
  created () {
    this.getHotLot()
  }
}
</script>

<style lang="scss" scoped>
.hot-lots {
  // height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .hot-lot {
    display: flex;
    flex-direction: column;
    align-items: center;
    // align-self: flex-start;
    h4 {
      color: #4F4F4F;
    }
  }
}
@media (max-width: 575.98px) {
  .hot-lots {
    flex-wrap: wrap;
    justify-content: flex-start;
    .hot-lot {
      width: 25%;
      // gap: 5px;
      margin-bottom: 15px;
      .lot-logo {
        width: 55px;
        height: 55px;
        margin-bottom: 5px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      h4 {
        font-weight: 500;
        font-size: 13px;
        font-size: calc(10px + (13 - 11) * (100vw - 576px) / (1200 - 576));
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .hot-lots {
    flex-wrap: wrap;
    justify-content: flex-start;
    .hot-lot {
      width: 25%;
      // gap: 5px;
      margin-bottom: 15px;
      .lot-logo {
        width: 55px;
        height: 55px;
        margin-bottom: 5px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      h4 {
        font-weight: 500;
        font-size: 13px;
        font-size: calc(10px + (13 - 11) * (100vw - 576px) / (1200 - 768));
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .hot-lots {
    padding: 0 15px 0 8px;
    .hot-lot {
      // gap: 15px;
      .lot-logo {
        width: 68px;
        height: 68px;
        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      h4 {
        font-weight: 500;
        font-size: 13px;
        font-size: calc(10px + (13 - 11) * (100vw - 992px) / (1200 - 768));
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hot-lots {
    padding: 0 30px;
    .hot-lot {
      // gap: 15px;
      .lot-logo {
        width: 76px;
        height: 76px;
        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      h4 {
        font-weight: 500;
        font-size: 15px;
        font-size: calc(13px + (15 - 13) * (100vw - 1199px) / (1200 - 992));
      }
    }
  }
}
@media (min-width: 1200px) {
  .hot-lots {
    padding: 0 45px;
    .hot-lot {
      // gap: 15px;
      .lot-logo {
        width: 80px;
        height: 80px;
        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      h4 {
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}
</style>
