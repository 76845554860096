<template>
  <div 
    class="table-view"
    :class="`${sport}-table-view`">
    <b-table-simple
      bordered>
      <b-thead>
        <b-tr>
          <b-th 
            v-for="field in fields"
            :key="field.key"
            :class="field.thClass">
            <span v-if="field.key === 'bet'" v-html="field.label"></span>
            <span v-else>{{field.label}}</span>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(row, rowidx) in data"
          :key="rowidx + row">
          <b-td
            v-for="(field, colidx) in fields"
            :class="field.tdClass"
            :key="field.key + colidx">
            <div v-if="field.key === 'play_at'" v-html="row[field.key]"></div>
            <div v-else-if="field.key === 'score'">
              <div 
                v-if="row.home_team_score !== '*' && row.away_team_score !== '*'" 
                class="score flex blue">
                <div>{{row.home_team_score ? row.home_team_score : '－'}}</div>
                <div> : </div>
                <div>{{row.away_team_score ? row.away_team_score : '－'}}</div>
              </div>
              <div v-else class="score red">＊</div>
            </div>
            <div v-else-if="field.key === 'result'">
              <span :class="`result-color-${row.result_code}`">{{row.result_code}}</span>
              <span>
                {{resultCode.hasOwnProperty(row.result_code) ? 
                ` (${resultCode[row.result_code]})` : ''}}
              </span>
            </div>
            <div v-else-if="field.key === 'bet'" class="flex">
              <div>3</div>
              <div>1</div>
              <div>0</div>
            </div>
            <div 
              v-else
              :class="field.key === 'scores' ? `result-color-${row.result_code}` : ''">
              {{row[field.key]}}
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  props: ['data', 'sport'],
  data () {
    return {
      fields: [
        { key: 'serialno', label: '场次', tdClass: 'serialno-col', thClass: 'serialno-col'},
        { key: 'league', label: '赛事', tdClass: 'league-col', thClass: 'league-col'},
        { key: 'play_at', label: '比赛时间', tdClass: 'play_at-col', thClass: 'play_at-col'},
        { key: 'home_team', label: '主场', tdClass: 'team-col', thClass: 'team-col'},
        { key: 'score', label: '比分', tdClass: 'score-col', thClass: 'score-col'},
        { key: 'away_team', label: '客场', tdClass: 'team-col', thClass: 'team-col'},
        { key: 'result', label: '彩果(胜负)', tdClass: 'result-col', thClass: 'result-col'},
        { key: 'bet', label: '下注金额试算器<br>(投注试算功能即将更新)', tdClass: 'bet-col', thClass: 'bet-col'}
        // { key: 'bet', label: '下注金额试算器<br>3(胜) 1(平) 0(负)', tdClass: 'bet-col', thClass: 'bet-col'}
      ],
      resultCode: {
        0: '主負',
        1: '平手',
        3: '主胜'
      }
    }
  },
  methods: {
    handleWinLose (code) {
      return code !== '-' ? `(${this.resultCode[code]})` : ''
    },
    handleScoreContent (data) {
      
    }
  }
}
</script>
