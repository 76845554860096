<template>
  <div class="ads-container"
    v-if="!isMobile && adsNum === 3">
    <a
      v-for="(ad, idx) in ads"
      :key="idx"
      class="ad-container"
      :href="ad[0].href"
      @click="sendVisit(ad[0].id)">
      <img :src="ad[0].photo" />
    </a>
  </div>
  <div class="ads-container"
    v-else-if="isMobile && ads[3]">
    <a
      class="ad-container"
      :href="ads[3][0].href"
      @click="sendVisit(ads[3][0].id)">
      <img :src="ads[3][0].photo" />
    </a>
  </div>
</template>

<script>
import { visit } from '@/api/client/visit'
import { getBanners } from '@/api/client/banner'
import resStatus from '@/constants/resStatus'
import { isMobile } from '@/utils/device'

export default {
  name: 'homeCommercial',
  data () {
    return {
      visitQuery: {
        to: 'ad',
        uuid: '',
        id: ''
      },
      bannerQuery: {
        position_ids: '3,4,5'
      },
      ads: []
    }
  },
  computed: {
    adsNum: {
      get () {
        return Object.keys(this.ads).length
      }
    },
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    sendVisit (id) {
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    getCommercials () {
      getBanners(this.bannerQuery)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.ads = res.data
          }
        })
    }
  },
  created () {
    this.getCommercials()
  }
}
</script>

<style lang="scss" scoped>
.ads-container {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  // gap: 5px;
  .ad-container {
    width: calc((100vw - 10px) / 100 * 39.6);
    height: calc((100vw - 10px) / 100 * 7);
    margin-right: 5px;
    &:last-child{margin-right: 0;}
    img {
      width: 100%;
      height: 100%;
    }
  }
}
@media (max-width: 575.98px) {
  .ads-container {
    padding: 15px;
    background: #FFF;
    .ad-container {
      width: 100%;
      height: calc((100vw - 30px) / 100 * 20.29);
      margin: 0;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ads-container {
    padding: 15px;
    background: #FFF;
    .ad-container {
      width: 100%;
      height: calc((100vw - 30px) / 100 * 20.29);
      margin: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {
  .ads-container {
    .ad-container {
      width: 396px;
      height: 70px;
    }
  }
}
</style>
