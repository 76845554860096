<template>
  <div class="lottery-result-container">
    <h3>最新开奖结果</h3>
    <Result
      v-for="result in resultData"
      :key="result.lotterycode"
      :result="result" />
    <!-- <button @click="handleSeeData">check</button> -->
  </div>
</template>

<script>
import { allLots } from '@/constants/lots'
import Result from './Result'
import { getIssueResult } from '@/api/client/display'
import resStatus from '@/constants/resStatus'

export default {
  name: 'mainLotteryResult',
  components: {
    Result
  },
  data () {
    return {
      resultData: [],
      allLots: allLots
    }
  },
  computed: {
    // resultData: {
    //   get () {
    //     return [
    //       // 欢乐生肖
    //       this.allLots
    //         .filter(item => item.id === 'LotHH')[0]
    //         .children
    //         .filter(item => item.id === 'CQSSC')[0],
    //       // 北京PK10
    //       this.allLots
    //         .filter(item => item.id === 'LotPK10')[0]
    //         .children
    //         .filter(item => item.id === 'BJPK10')[0],
    //       // 腾讯分分彩
    //       this.allLots
    //         .filter(item => item.id === 'LotHH')[0]
    //         .children
    //         .filter(item => item.id === 'TXFFC')[0]
    //     ]
    //   }
    // },
    fakeResults: {
      get () {
        const fakeResult = {
          name: '双色球',
          date: '2020102期 10-18 周日',
          result: this.fakeNumbers
        }
        return (new Array(3)).fill(fakeResult)
      }
    },
    fakeNumbers: {
      get () {
        const howMany = 10
        return new Array(howMany).fill('29')
      }
    }
  },
  methods: {
    getNewestLotResult () {
      getIssueResult()
        .then(res => {
          if (res.status === resStatus.OK) {
            this.resultData = res.data.issue_result
          }
        })
    }
  },
  created () {
    this.getNewestLotResult()
  }
}
</script>

<style lang="scss" scoped>
.lottery-result-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  h3 {
    color: #EB5757;
  }
}
@media (max-width: 575.98px) {}
@media (min-width: 576px) and (max-width: 767.98px) {}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lottery-result-container {
    h3 {
      font-size: 15px;
      font-weight: 500;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lottery-result-container {
    h3 {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
@media (min-width: 1200px) {
  .lottery-result-container {
    h3 {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
</style>
