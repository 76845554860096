<script>
import demo from './Template/index'

export default {
  ...demo,
  data () {
    return {
      ...demo.data(),
      tab: 'LotPK10'
    }
  },
  computed: {
    ...demo.computed,
    content: {
      get () {
        return [
          // 北京PK10
          this.tabLots
            .filter(item => item.id === 'BJPK10')[0],
          // 腾讯PK10
          this.tabLots
            .filter(item => item.id === 'TXPK10')[0],
          // 幸运飞艇
          this.tabLots
            .filter(item => item.id === 'XIYFT')[0],
          // 皇家幸运飞艇
          this.tabLots
            .filter(item => item.id === 'XYFT')[0]
        ]
      }
    }
  }
}
</script>
