<script>
import demo from './Template/index'

export default {
  ...demo,
  data () {
    return {
      ...demo.data(),
      tab: 'LotQ3'
    }
  },
  computed: {
    ...demo.computed,
    content: {
      get () {
        return [
          // 江苏快三
          this.tabLots
            .filter(item => item.id === 'JSKS')[0],
          // 河南快三
          this.tabLots
            .filter(item => item.id === 'HLK3')[0],
          // 湖北快三
          this.tabLots
            .filter(item => item.id === 'HBK3')[0],
          // 广西快三
          this.tabLots
            .filter(item => item.id === 'GXKS')[0]
        ]
      }
    }
  }
}
</script>
