<template>
  <div 
    class="table-view"
    :class="`${sport}-table-view`">
    <div
      v-for="(item, idx) in tableData"
      :key="item + idx"
      class="table-section">
      <b-table-simple
        fixed
        bordered>
        <b-thead
          v-if="idx === 0">
          <b-tr>
            <b-th 
              v-for="field in fields"
              :key="field.key"
              :class="field.thClass"
              :rowspan="field.key !== 'bet' ? 2 : 1"
              :colspan="field.key === 'bet' ? 2 : 1">
              {{field.label}}
            </b-th>
          </b-tr>
          <b-tr>
            <b-th class="ds-col yellow">大</b-th>
            <b-th class="ds-col yellow">小</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(row, i) in item"
            :key="i + row">
            <b-td
              v-for="(field, colidx) in fields" 
              v-show="i % 2 == 0 || i % 2 !== 0 && (field.key !== 'serialno' && field.key !== 'league' && field.key !== 'play_at' && field.key !== 'total_score' && field.key !== 'bet')"
              :colspan="field.key === 'bet' ? 2 : 1"
              :rowspan="i % 2 == 0 && (field.key == 'serialno' || field.key == 'league' || field.key == 'play_at'  || field.key == 'total_score' || field.key == 'bet') ? 2 : 1"
              :key="field + colidx"
              :class="field.tdClass">
              <div
                v-if="field.key === 'play_at' || field.key === 'spread' || field.key === 'team'"
                :class="{'flex': field.key === 'team'}"
                v-html="row[field.key]"></div>
              <div
                v-else-if="field.key === 'bet'"
                class="flex">
                <div class="w-50">
                  <span>{{row.big_rate}}</span>
                </div>
                <div class="w-50">
                  <span>{{row.small_rate}}</span>
                </div>
              </div>
              <div v-else>{{row[field.key]}}</div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'sport'],
  data () {
    return {
      result: null,
      fields: [
        { key: 'serialno', label: '场次', tdClass: 'serialno-col', thClass: 'serialno-col'},
        { key: 'league', label: '赛事', tdClass: 'league-col', thClass: 'league-col'},
        { key: 'play_at', label: '比赛时间', tdClass: 'play_at-col', thClass: 'play_at-col'},
        { key: 'team', label: '主场', tdClass: 'team-col', thClass: 'team-col'},
        { key: 'score', label: '比分', tdClass: 'score-col', thClass: 'score-col'},
        { key: 'total_score', label: '预设总分', tdClass: 'total_score-col', thClass: 'total_score-col'},
        { key: 'bet', label: '投注', tdClass: 'bet-col', thClass: 'bet-col'},
      ],
      resultCode: {
        0: '主負',
        1: '平手',
        3: '主胜'
      }
    }
  },
  computed: {
    tableData: {
      get () {
        return this.result
      },
      set (data) {
        let arr = [{}]
        if (data) {
          arr = data.map(item => {
            return this.handleData(item)
          })
        }
        this.result = arr
      }
    }
  },
  created () {
    this.tableData = this.data
  },
  watch: {
    data (cur, prev) {
      if (cur !== prev) 
        this.tableData = this.data
    }
  },
  methods: {
    handleData (obj) {
      let arr = []
      for(let i = 0; i < 2; i++) {
        let serial = this.serialNum(String(obj.serialno)) || obj.serialno
        let dsf = obj.dsf, firstCol = i % 2 === 0
        arr.push({
          serialno: typeof(serial) === 'object' ? serial[i] : serial,
          league: obj.league,
          play_at: obj.play_at,
          team: `<span class="red">${firstCol ? '客' : '主'}</span>${firstCol ? obj.away_team : obj.home_team}`,
          score: firstCol ? obj.away_team_score || '－' : obj.home_team_score || '－',
          total_score: dsf.total_score,
          big_rate: dsf.big_rate,
          small_rate: dsf.small_rate
        })
      }
      return arr
    },
    serialNum (str) {
      return str.indexOf('-') > -1 ? str.split('-') : null
    },
    handleWinLose (code) {
      return code !== '-' ? `(${this.resultCode[code]})` : ''
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex <= 2 || columnIndex > 4) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>