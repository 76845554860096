import request from '@/utils/axios'

export function getLotWinNum (params) {
  return request({
    url: '/api/lottery/winning_number',
    method: 'get',
    params: params
  })
}

export function getLotWinNumHistory (params) {
  return request({
    url: '/api/lottery/winning_number/history',
    method: 'get',
    params: params
  })
}

export function getLotWinNumPredict (params) {
  return request({
    url: '/api/lottery/winning_number/predict',
    method: 'get',
    params: params
  })
}
