<script>
import demo from './Template/index'

export default {
  ...demo,
  data () {
    return {
      ...demo.data(),
      tab: 'LotOversea'
    }
  },
  computed: {
    ...demo.computed,
    content: {
      get () {
        return [
          // 河内五分彩
          this.tabLots
            .filter(item => item.id === 'HN300')[0],
          // 河内分分彩
          this.tabLots
            .filter(item => item.id === 'HN60')[0],
          // 加拿大3D
          this.tabLots
            .filter(item => item.id === 'JND3D')[0],
          // 泰国11选5
          this.tabLots
            .filter(item => item.id === 'TG11X5')[0]
        ]
      }
    }
  }
}
</script>
