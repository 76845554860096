/*
  快3
  import {} from '@/utils/display/Q3'
*/
import { commonTotal } from '@/utils/display/common'

// 鱼、虾、蟹、鸡、葫芦、金钱
export function Q3FSC (n) {
  var i
  var newN = Number(n)
  switch (newN) {
    case 1:
      i = '鱼'
      break
    case 2:
      i = '虾'
      break
    case 3:
      i = '葫芦'
      break
    case 4:
      i = '金钱'
      break
    case 5:
      i = '蟹'
      break
    case 6:
      i = '鸡'
      break
    default:
      i = 'ERR'
      break
  }
  return i
}

// 總和大小
export function Q3TotalBS (n, limit) {
  if (Q3AllEat(n)) {
    return Q3AllEat(n)
  }

  const total = commonTotal(n)
  if (total >= 11) {
    return '大'
  }
  return '小'
}

// 總和單雙
export function Q3TotalOE (n) {
  if (Q3AllEat(n)) {
    return Q3AllEat(n)
  }

  const total = commonTotal(n)
  if (total % 2 === 0) {
    return '双'
  }
  return '单'
}

// 三位數相同: 「通吃」
export function Q3AllEat (n) {
  var f30 = Number(n[0])
  var f31 = Number(n[1])
  var f32 = Number(n[2])
  if (
    f30 === f31 &&
    f31 === f32
  ) {
    return '通吃'
  }
  return false
}
