<template>
  <div class="d-container">
    <!-- 總和 -->
    <div
      v-if="displayItem.total"
      class="d-item">
      <div class="head">总和</div>
      <div class="content">
        <div>{{ getTotal() }}</div>
        <div>{{ getTotalOE() }}</div>
        <div
          v-if="!displayItem.totalP5">
          {{ getTotalBS() }}
        </div>
        <div v-else>
          {{ getTotalP5BS() }}
        </div>
        <div
          v-if="displayItem.lbs">
          {{ getTotalLBS() }}
        </div>
      </div>
    </div>

    <!-- Q3總和 -->
    <div
      v-if="displayItem.totalQ3"
      class="d-item">
      <div class="head">总和</div>
      <div class="content">
        <div>{{ getTotal() }}</div>
        <div>{{ getTotalQ3OE() }}</div>
        <div>{{ getTotalQ3BS() }}</div>
      </div>
    </div>

    <!-- 1-5龍虎 -->
    <div
      v-if="displayItem.dg5"
      class="d-item">
      <div class="head">1-5龙虎</div>
      <div class="content">
        <div>{{ getPK10DT1() }}</div>
        <div>{{ getPK10DT2() }}</div>
        <div>{{ getPK10DT3() }}</div>
        <div>{{ getPK10DT4() }}</div>
        <div>{{ getPK10DT5() }}</div>
      </div>
    </div>

    <!-- 1-4龍虎 -->
    <div
      v-if="displayItem.dg4"
      class="d-item">
      <div class="head">1-4龙虎</div>
      <div class="content">
        <div>{{ getPK10DT1() }}</div>
        <div>{{ getPK10DT2() }}</div>
        <div>{{ getPK10DT3() }}</div>
        <div>{{ getPK10DT4() }}</div>
      </div>
    </div>

    <!-- 冠亞和 -->
    <div
      v-if="displayItem.cr"
      class="d-item">
      <div class="head">冠亞和</div>
      <div class="content">
        <div>{{ getPK10Total() }}</div>
      </div>
    </div>

    <!-- 龍虎 -->
    <div
      v-if="displayItem.dg"
      class="d-item">
      <div class="head">龙虎</div>
      <div class="content">
        <div>{{ getMMDT() }}</div>
      </div>
    </div>

    <!-- 前、中、後三 -->
    <div
      v-if="displayItem.mf3"
      class="d-item">
      <div class="head">前三</div>
      <div class="content">
        <div>{{ getMMF3() }}</div>
      </div>
    </div>
    <div
      v-if="displayItem.mm3"
      class="d-item">
      <div class="head">中三</div>
      <div class="content">
        <div>{{ getMMM3() }}</div>
      </div>
    </div>
    <div
      v-if="displayItem.ml3"
      class="d-item">
      <div class="head">后三</div>
      <div class="content">
        <div>{{ getMML3() }}</div>
      </div>
    </div>

    <!-- 11P5 前、中、後三 -->
    <div
      v-if="displayItem.pf3"
      class="d-item">
      <div class="head">前三</div>
      <div class="content">
        <div>{{ getP5F3() }}</div>
      </div>
    </div>
    <div
      v-if="displayItem.pm3"
      class="d-item">
      <div class="head">中三</div>
      <div class="content">
        <div>{{ getP5M3() }}</div>
      </div>
    </div>
    <div
      v-if="displayItem.pl3"
      class="d-item">
      <div class="head">后三</div>
      <div class="content">
        <div>{{ getP5L3() }}</div>
      </div>
    </div>

    <!-- 鱼虾蟹 -->
    <div
      v-if="displayItem.fsc"
      class="d-item">
      <div class="head">鱼虾蟹</div>
      <div class="content">
        <div>{{ getQ3FSC1() }}</div>
        <div>{{ getQ3FSC2() }}</div>
        <div>{{ getQ3FSC3() }}</div>
      </div>
    </div>

    <!-- 個十百 -->
    <div
      v-if="displayItem.uth"
      class="d-item">
      <div class="head">百</div>
      <div class="content">
        <div>{{ getOEH() }} / {{ getBSH() }}</div>
      </div>
    </div>
    <div
      v-if="displayItem.uth"
      class="d-item">
      <div class="head">十</div>
      <div class="content">
        <div>{{ getOET() }} / {{ getBST()  }}</div>
      </div>
    </div>
    <div
      v-if="displayItem.uth"
      class="d-item">
      <div class="head">個</div>
      <div class="content">
        <div>{{ getOEU() }} / {{ getBSU() }}</div>
      </div>
    </div>

    <!-- <div
      v-if="displayItem.test"
      class="d-item">
      aslkdjfa;lsdkjfa;lskdjf
    </div> -->
  </div>
</template>

<script>
import { commonTotal, commonOE, commonBS, commonTotalLBS } from '@/utils/display/common'
import { MMDT, MMThree } from '@/utils/display/MM'
import { Q3FSC, Q3TotalOE, Q3TotalBS } from '@/utils/display/Q3'
import { PK10Total, PK10DT } from '@/utils/display/PK10'
import { P5Three, P5TotalBS } from '@/utils/display/11P5'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    displayItem: {
      type: Object,
      required: true
    },
    codeNums: {
      type: Array,
      required: true
    }
  },
  // computed: {
  //   totalOE: {
  //     get () {
  //       return commonOE(this.totalSum)
  //     }
  //   },
  //   totalBS: {
  //     get () {
  //       return commonBS(this.totalSum, 23)
  //     }
  //   }
  // },
  data () {
    return {
      totalSum: 0
    }
  },
  methods: {
    getTotal () {
      this.totalSum = commonTotal(this.codeNums)
      return this.totalSum
    },
    getTotalQ3OE () {
      return Q3TotalOE(this.codeNums)
    },
    getTotalQ3BS () {
      return Q3TotalBS(this.codeNums)
    },
    getTotalOE () {
      return commonOE(this.totalSum)
    },
    getTotalBS () {
      return commonBS(this.totalSum, 23)
    },
    getTotalP5BS () {
      return P5TotalBS(this.codeNums)
    },
    getBSH () {
      return commonBS(this.codeNums[0], 5)
    },
    getBST () {
      return commonBS(this.codeNums[1], 5)
    },
    getBSU () {
      return commonBS(this.codeNums[2], 5)
    },
    getOEH () {
      return commonOE(this.codeNums[0])
    },
    getOET () {
      return commonOE(this.codeNums[1])
    },
    getOEU () {
      return commonOE(this.codeNums[2])
    },
    getTotalLBS () {
      return commonTotalLBS(this.codeNums, 5)
    },
    getPK10DT1 () {
      return PK10DT(this.codeNums, 0)
    },
    getPK10DT2 () {
      return PK10DT(this.codeNums, 1)
    },
    getPK10DT3 () {
      return PK10DT(this.codeNums, 2)
    },
    getPK10DT4 () {
      return PK10DT(this.codeNums, 3)
    },
    getPK10DT5 () {
      return PK10DT(this.codeNums, 4)
    },
    getPK10Total () {
      return PK10Total(this.codeNums)
    },
    getQ3FSC1 () {
      return Q3FSC(this.codeNums[0])
    },
    getQ3FSC2 () {
      return Q3FSC(this.codeNums[1])
    },
    getQ3FSC3 () {
      return Q3FSC(this.codeNums[2])
    },
    getMMDT () {
      return MMDT(this.codeNums)
    },
    getMMF3 () {
      return MMThree(this.codeNums, 'f')
    },
    getMMM3 () {
      return MMThree(this.codeNums, 'm')
    },
    getMML3 () {
      return MMThree(this.codeNums, 'l')
    },
    getP5F3 () {
      return P5Three(this.codeNums, 'f')
    },
    getP5M3 () {
      return P5Three(this.codeNums, 'm')
    },
    getP5L3 () {
      return P5Three(this.codeNums, 'l')
    }
    // isBeside (numA, numB) {

    // }
  }
  // mounted () {
  //   console.log('updated: ', this.codeNums)
  //   this.getTotal()
  // }
}
</script>

<style lang="scss" scoped>
$border-color: #D7D7D7;
$border: 1px solid $border-color;
.d-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .d-item {
    flex-grow: 99;
    display: flex;
    flex-direction: column;
    .head {
      color: #EB5757;
      border-top: $border;
      border-right: $border;
      border-bottom: $border;
    }
    .content {
      color: #828282;
      display: flex;
      justify-content: space-between;
      div {
        border-right: $border;
        border-bottom: $border;
        width: 100%;
      }
    }
  }
  .d-item:first-child {
    .head {
      border-left: $border;
    }
    .content {
      border-left: $border;
    }
  }
}
@media (max-width: 575.98px) {
  .d-container {
    width: fit-content;
    display: block;
    text-align: left;
    .d-item {
      display: inline-block;
      margin-right: 15px;
      // &:last-child{
      //   &::before{
      //     content: '';
      //     display: inline-block;
      //     width: 1px;
      //     height: 11px;
      //     background: #828282;
      //     vertical-align: middle;
      //     margin: 0 10px 0 5px;
      //   }
      // }
      .head {
        display: inline-block;
        color: #EB5757;
        border: none;
        font-weight: 500;
        margin-right: 5px;
      }
      .content {
        color: #828282;
        display: inline-block;
        div {
          display: inline-block;
          border: none;
          width: fit-content;
          margin-right: 5px;
        }
      }
    }
    .d-item:first-child {
      .head {
        border-left: none;
      }
      .content {
        border-left: none;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .d-container {
    width: fit-content;
    display: block;
    text-align: left;
    .d-item {
      display: inline-block;
      margin-right: 15px;
      // &:last-child{
      //   &::before{
      //     content: '';
      //     display: inline-block;
      //     width: 1px;
      //     height: 11px;
      //     background: #828282;
      //     vertical-align: middle;
      //     margin: 0 10px 0 5px;
      //   }
      // }
      .head {
        display: inline-block;
        color: #EB5757;
        border: none;
        font-weight: 500;
        margin-right: 5px;
      }
      .content {
        color: #828282;
        display: inline-block;
        div {
          display: inline-block;
          border: none;
          width: fit-content;
          margin-right: 5px;
        }
      }
    }
    .d-item:first-child {
      .head {
        border-left: none;
      }
      .content {
        border-left: none;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .d-container {
    .d-item {
      font-size: 14px;
      .head {
        font-weight: 500;
        padding: 8px 0;
      }
      .content {
        div {
          padding: 6px 0;
          line-height: 1em;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .d-container {
    .d-item {
      font-size: 15px;
      .head {
        font-weight: 500;
        padding: 8px 0;
      }
      .content {
        div {
          padding: 6px 0;
          line-height: 1em;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .d-container {
    .d-item {
      font-size: 15px;
      .head {
        font-weight: 500;
        padding: 8px 0;
      }
      .content {
        div {
          padding: 6px 0;
          line-height: 1em;
        }
      }
    }
  }
}
</style>
