<template>
  <div 
    class="table-view"
    :class="`${sport}-table-view`">
    <div
      v-for="(item, idx) in tableData"
      :key="item + idx"
      class="table-section">
      <b-table-simple
        fixed
        bordered>
        <b-thead
          v-if="idx === 0">
          <b-tr>
            <b-th 
              v-for="field in fields"
              :key="field.key"
              :class="field.thClass">
              {{field.label}}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(row, i) in item"
            :key="i + row">
            <b-td
              v-for="(field, colidx) in fields" 
              v-show="i % 2 == 0 || i % 2 !== 0 && (field.key !== 'serialno' && field.key !== 'league' && field.key !== 'play_at')"
              :rowspan="i % 2 == 0 && (field.key == 'serialno' || field.key == 'league' || field.key == 'play_at') ? 2 : 1"
              :key="field + colidx"
              :class="field.tdClass">
              <div
                v-if="field.key === 'play_at' || field.key === 'spread' || field.key === 'team'"
                :class="{'flex': field.key === 'team'}"
                v-html="row[field.key]">
              </div>
              <div v-else-if="field.key.indexOf('rate') > -1">
                {{row.bet[field.key]}}
              </div>
              <div v-else>{{row[field.key]}}</div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'sport'],
  data () {
    return {
      result: null,
      resultCode: {
        0: '主負',
        1: '平手',
        3: '主胜'
      },
      fields: [
        { key: 'serialno', label: '场次', tdClass: 'serialno-col', thClass: 'serialno-col'},
        { key: 'league', label: '赛事', tdClass: 'league-col', thClass: 'league-col'},
        { key: 'play_at', label: '比赛时间', tdClass: 'play_at-col', thClass: 'play_at-col'},
        { key: 'team', label: '主场', tdClass: 'team-col', thClass: 'team-col'},
        { key: 'score', label: '比分', tdClass: 'score-col', thClass: 'score-col'},
        { key: '1_5_rate', label: '1～5', tdClass: 'bet-rate-col', thClass: 'bet-rate-col'},
        { key: '6_10_rate', label: '6～10', tdClass: 'bet-rate-col', thClass: 'bet-rate-col'},
        { key: '11_15_rate', label: '11～15', tdClass: 'bet-rate-col', thClass: 'bet-rate-col'},
        { key: '16_20_rate', label: '16～20', tdClass: 'bet-rate-col', thClass: 'bet-rate-col'},
        { key: '20_25_rate', label: '21～25', tdClass: 'bet-rate-col', thClass: 'bet-rate-col'},
        { key: '26plus_rate', label: '26＋', tdClass: 'bet-rate-col', thClass: 'bet-rate-col'},
      ]
    }
  },
  computed: {
    tableData: {
      get () {
        return this.result
      },
      set (data) {
        let arr = [{}]
        if (data) {
          arr = data.map(item => {
            return this.handleData(item)
          })
        }
        this.result = arr
      }
    }
  },
  created () {
    this.tableData = this.data
  },
  watch: {
    data (cur, prev) {
      if (cur !== prev) 
        this.tableData = this.data
    }
  },
  methods: {
    handleData (obj) {
      let arr = []
      for(let i = 0; i < 2; i++) {
        let serial = this.serialNum(String(obj.serialno)) || obj.serialno
        let sfc = obj.sfc, firstCol = i % 2 === 0,
            awayRateObj = {}, homeRateObj = {}
        for(let key in sfc) {
          if (firstCol && key.indexOf('away') > -1)
            awayRateObj[key.replace('away_', '')] = sfc[key]
          else
            homeRateObj[key.replace('home_', '')] = sfc[key]
        }
        let reateObj = firstCol ? awayRateObj : homeRateObj
        arr.push({
          serialno: typeof(serial) === 'object' ? serial[i] : serial,
          league: obj.league,
          play_at: obj.play_at,
          team: `<span class="red">${firstCol ? '客' : '主'}</span>${firstCol ? obj.away_team : obj.home_team}`,
          score: firstCol ? obj.away_team_score || '-' : obj.home_team_score || '-',
          bet: reateObj
        })
      }
      return arr
    },
    serialNum (str) {
      return str.indexOf('-') > -1 ? str.split('-') : null
    },
    handleWinLose (code) {
      return code !== '-' ? `(${this.resultCode[code]})` : ''
    }
  }
}
</script>

<style lang="scss">
</style>