<template>
  <div class="logo-container">
    <div
    :style="{ backgroundImage: `url(${logo})` }"
    class="logo">
    </div>
    <h3>热门彩种</h3>
  </div>
</template>

<script>
import logo from '@/assets/home-hot-lot-logo.png'

export default {
  name: 'hotLotLogo',
  data () {
    return {
      logo
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .logo {
    // position: relative;
    position: absolute;
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: 100.2% 100.1%;
  }
  .logo::before {
    background: linear-gradient(16.1deg, #F03D3D 7.81%, #FF8D3A 66.36%, #FFCC48 92.98%);
    opacity: 0.85;
    content: '';
    display: block;
    height: 120%;
    // position: absolute;
    width: 100%;
    transform-origin: right top;
    transform: rotate(9.6deg);
  }
  h3 {
    position: relative;
    color: #FFFFFF;
    right: 8px;
    z-index: 0;
  }
}
@media (max-width: 575.98px) {
  .logo-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
    padding: 0 15px;
    .logo {display: none;}
    .logo::before {display: none;}
    h3 {
      position: unset;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      z-index: 0;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .logo-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
    padding: 0 15px;
    .logo {display: none;}
    .logo::before {display: none;}
    h3 {
      position: unset;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      z-index: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .logo-container {
    width: 132px;
    height: 158px;
    h3 {
      font-size: 22px;
    }
    .logo::before {
      transform: rotate(9.4deg);
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .logo-container {
    width: 171px;
    height: 198px;
    h3 {
      font-size: 28px;
    }
  }
}
@media (min-width: 1200px) {
  .logo-container {
    width: 190px;
    height: 220px;
    h3 {
      font-size: 30px;
    }
  }
}
</style>
