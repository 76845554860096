export function contentLimit (data) {
  const temp = JSON.parse(JSON.stringify(data))
  // const viewWidth = window.innerWidth
  // const titleLength = viewWidth >= 992 ? 500 : 50
  // const contentLength = viewWidth >= 1200 ? 100 : viewWidth >= 992 ? 500 : 150
  const dataArr = temp.filter(ele => {
    // if (ele.title.length > titleLength) {
    //   ele.title = ele.title.slice(0, titleLength) + '...'
    // }
    ele.content = ele.content.replace(/<(.|\n)*?>/g, '')
    // if (ele.content.length > contentLength) {
    //   ele.content = ele.content.slice(0, (contentLength - 30)) + '...'
    // }    
    return ele
  })
  return dataArr
}
