<template>
  <div class="head-container">
    <router-link
      :to="lotHistoryTo"
      class="logo">
      <img :src="lotLogo" />
    </router-link>
    <h4><span class="lot-name">{{ lotName }}</span> <span class="lot-issue">{{ issue }}</span>期</h4>
    <div class="remark">
      <p v-if="hasCycle">每{{ cycle }}一期，全天{{ totalIssue }}期，当前{{ nowIssue }}期，剩余{{ getRestIssue() }}期</p>
      <p v-else>-</p>
    </div>
    <div class="status">
      <div v-if="lotStatus === 'open'">
        <p>开奖倒数</p>
        <p>
          <span v-if="getNextOpenH() !== 0"><span v-if="getNextOpenM() < 10">0</span>{{ getNextOpenH() }}:</span>
          <span><span v-if="getNextOpenM() < 10">0</span>{{ getNextOpenM() }}:</span>
          <span><span v-if="getNextOpenS() < 10">0</span>{{ getNextOpenS() }}</span>
        </p>
      </div>
      <div v-if="lotStatus === 'close'">
        <p>尚未开奖</p>
      </div>
      <div v-if="lotStatus === 'error'">
        <p>维护中...</p>
      </div>
      <div v-if="lotStatus === 'continue'">
        <p>开奖中...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getLotTabById } from '@/utils/lot'
export default {
  props: {
    lotInfo: {
      type: Object,
      required: true
    },
    response: {
      type: Object,
      required: true
    },
    lotStatus: {
      type: String,
      required: true
    },
    nextOpen: {
      type: Number,
      required: true
    }
  },
  computed: {
    lotHistoryTo: {
      get () {
        return {
          name: 'LotteryResult',
          query: {
            id: this.lotInfo.id,
            tab: getLotTabById(this.lotInfo.id).id
          }
        }
      }
    },
    lotLogo: {
      get () {
        return this.lotInfo.logo || ''
      }
    },
    hasCycle: {
      get () {
        return (
          this.lotStatus === 'open' ||
          this.lotStatus === 'continue'
        )
      }
    },
    lotName: {
      get () {
        if (!this.lotInfo.name) {
          return '彩种名称未定'
        }
        return this.lotInfo.name
      }
    },
    resLot: {
      get () {
        if (!this.response.lottery) {
          return {}
        }
        return this.response.lottery
      }
    },
    issue: {
      get () {
        if (!this.resLot.last_issue) {
          return ' --'
        }
        return this.resLot.last_issue
      }
    },
    cycle: {
      get () {
        const cycle = Number(this.resLot.cycle) || 0
        if (cycle < 60) {
          return `${cycle}秒`
        }
        return `${Math.floor(cycle / 60)}分种`
      }
    },
    totalIssue: {
      get () {
        if (!this.resLot.total_issue) {
          return 0
        }
        return this.resLot.total_issue
      }
    },
    nowIssue: {
      get () {
        if (!this.resLot.now_issue) {
          return 0
        }
        return this.resLot.now_issue
      }
    }
    // nextOpen: {
    //   get () {
    //     if (!this.resLot.next_open_remaining) {
    //       return 0
    //     }
    //     return this.resLot.next_open_remaining
    //   },
    //   set (num) {
    //     this.$emit('changeNextOpen', num)
    //   }
    // }
  },
  methods: {
    getRate (t) {
      return t / 60
    },
    getRestIssue () {
      const rest = this.totalIssue - this.nowIssue
      if (rest >= 0) {
        return rest
      }
      return 0
    },
    getNextOpenH () {
      const h = Math.floor(this.nextOpen / 3600)
      return h
    },
    getNextOpenM () {
      const h = this.getNextOpenH()
      const m = Math.floor(this.nextOpen / 60) - h * 60
      return m
    },
    getNextOpenS () {
      const s = Math.floor(this.nextOpen % 60)
      return s
    }
  }
}
</script>

<style lang="scss" scoped>
.head-container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .logo {
    img {
      width: 100%;
      height: 100%;
    }
  }
  h4 {
    color: #4F4F4F;
    .lot-issue {
      color: #EB5757;
    }
  }
  p {
    color: #4F4F4F;
  }
  .status {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    p {
      color: #EB5757;
    }
  }
}
@media (max-width: 575.98px) {
  .head-container {
    height: max-content;
    align-items: flex-start;
    .logo {
      display: none;
    }
    h4 {
      font-weight: normal;
      font-size: 12px;
      .lot-name {
        font-weight: 600;
        font-size: 14px;
      }
    }
    .remark{display: none;}
    .status {
      p {
        display: inline;
        font-size: 12px;
        &:first-child{
          color: #4F4F4F;
          padding-right: 3px;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .head-container {
    height: max-content;
    align-items: flex-start;
    .logo {
      display: none;
    }
    h4 {
      font-weight: normal;
      font-size: 12px;
      .lot-name {
        font-weight: 600;
        font-size: 14px;
      }
    }
    .remark{display: none;}
    .status {
      p {
        display: inline;
        font-size: 12px;
        &:first-child{
          color: #4F4F4F;
          padding-right: 3px;
        }
        & > span{margin: 0 2px;}
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .head-container {
    height: 140px;
    .logo {
      width: 72px;
      height: 72px;
      min-width: 72px;
      min-height: 72px;
      border-radius: 5px;
      img {
        border-radius: 5px;
      }
    }
    h4 {
      font-weight: normal;
      font-size: 16px;
      .lot-name {
        font-weight: 600;
        font-size: 18px;
      }
    }
    p {
      font-size: 14px;
    }
    .status {
      p {
        font-size: 13px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .head-container {
    height: 140px;
    .logo {
      width: 80px;
      height: 80px;
      min-width: 80px;
      min-height: 80px;
      border-radius: 5px;
      img {
        border-radius: 5px;
      }
    }
    h4 {
      font-weight: normal;
      font-size: 18px;
      .lot-name {
        font-weight: 600;
        font-size: 20px;
      }
    }
    p {
      font-size: 14px;
    }
    .status {
      p {
        font-size: 13px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .head-container {
    height: 140px;
    .logo {
      width: 80px;
      height: 80px;
      min-width: 80px;
      min-height: 80px;
      border-radius: 5px;
      img {
        border-radius: 5px;
      }
    }
    h4 {
      font-weight: normal;
      font-size: 18px;
      .lot-name {
        font-weight: 600;
        font-size: 20px;
      }
    }
    p {
      font-size: 14px;
    }
    .status {
      p {
        font-size: 13px;
      }
    }
  }
}
</style>
