import { allLots } from '@/constants/lots'

export function getLotTabById (id) {
  return (
    allLots
      .filter(item => {
        return (
          item.children
            .filter(lot => {
              return lot.id === id
            })
            .length !== 0
        )
      })[0]
  )
}

export function getLotInfoById (id) {
  return (
    getLotTabById(id)
      .children
      .filter(lot => {
        return lot.id === id
      })[0]
  )
}

export function isOdd (num) {
  if (Number(num) % 2 === 0) {
    return false
  }
  return true
}

export function isBig (num, maxNum, minNum) {
  const middle = (Number(maxNum) + Number(minNum)) / 2
  if (num < middle) {
    return false
  } else if (num > middle) {
    return true
  } else {
    return null
  }
}

export function isPrime (num) {
  for (let i = 2; i < num; i++) {
    if (num % i === 0) {
      return false
    }
  }
  return num >= 1
}

// 龍虎走勢
export function isDT (num0, num1) {
  if (Number(num0) > Number(num1)) {
    return 'd'
  } else if (Number(num0) < Number(num1)) {
    return 't'
  } else {
    return 'e'
  }
}

// 豹子
export function isThreeKind (numArr) {
  if (Number(numArr[0]) === Number(numArr[1]) && Number(numArr[1]) === Number(numArr[2])) {
    return true
  }
  return false
}

// 顺子
export function isStraight (numArr) {
  numArr.sort(function(a, b) {
    return Number(b) - Number(a)
  })
  let straight = true
  for (let i = 0; i < numArr.length; i++) {
    if (i == numArr.length - 1)
      break
    if (Number(numArr[i]) - Number(numArr[i + 1]) !== 1) {
      straight = false
      break
    }
  }
  return straight
}

// 对子
export function isPair (numArr) {
  numArr.sort(function(a, b) {
    return Number(b) - Number(a)
  })
  if ((Number(numArr[0]) === Number(numArr[1]) || Number(numArr[1]) === Number(numArr[2])) &&
      Number(numArr[0]) !== Number(numArr[2])) {
    return true
  }
  return false
}

// 半顺
export function isHalfStraight (numArr) {
  numArr.sort(function(a, b) {
    return Number(b) - Number(a)
  })
  let straight = false
  for (let i = 0; i < numArr.length; i++) {
    if (Number(numArr[i]) - Number(numArr[i + 1]) == 1) {
      straight = true
      break
    }
  }
  return straight
}

// 組六
export function isAllDiff (numArr) {
  if (Number(numArr[0]) !== Number(numArr[1]) && Number(numArr[0]) !== Number(numArr[2]) && Number(numArr[1]) !== Number(numArr[2])) {
    return true
  }
  return false
}
