<template>
  <router-link
    :to="handleRouterTo()" >
    定位走势
  </router-link>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    handleRouterTo () {
      return {
        name: 'LotteryChartDetail',
        query: {
          id: this.id,
          chart: 'dyTrend'
        }
      }
    }
  }
}
</script>
