import request from '@/utils/axios'

// 取得 彩友分享列表
export function getArticleList (params) {
  return request({
    url: '/api/info/user_article',
    method: 'get',
    params: params
  })
}
  
//   取得單一 新聞/玩法訊息/專屬福利/用戶發文(news/winskill/benefit/user_article)
export function getArticle (id) {
  return request({
    url: `/api/info/user_article/${id}`,
    method: 'get'
  })
}

export function sendLike (id) {
  return request({
    url: `/api/info/user_article/${id}?action=like`,
    method: 'get'
  })
}

export function reportUserArticle (id) {
  return request({
    url: `/api/info/user_article/${id}/report`,
    method: 'post'
  })
}