<template>
  <div 
    class="table-view"
    :class="`${sport}-table-view`">
    <div
      v-for="(item, idx) in tableData"
      :key="item + idx"
      class="table-section hhqq-table-section">
      <b-table-simple
        bordered 
        >
        <b-thead v-show="idx === 0">
          <b-tr>
            <b-th 
              v-for="(field, key, i) in fields"
              :class="`${key}-col`"
              :colspan="i === Object.keys(fields).length - 1 ? 2 : 1"
              :key="field + i">
              {{field}}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td rowspan="3" class="serialno-col">{{item.serialno}}</b-td>
            <b-td rowspan="3" class="league-col">{{item.league}}</b-td>
            <b-td rowspan="3" class="playtime-col">
              <div v-html="item.play_at"></div>
            </b-td>
            <b-td rowspan="3" class="team-col hhqq-team-col">
              <div class="flex flex-around">
                <div>
                  <div class="red">客</div>
                  <div>{{item.away_team}}</div>
                </div>
                <div>VS</div>
                <div>
                  <div class="red">主</div>
                  <div>{{item.home_team}}</div>
                </div>
              </div>
            </b-td>
            <b-td class="score-col">
              <div class="flex flex-around">
                <span class="red">客</span>
                <div class="text-right">{{item.away_team_score || '-'}}</div>
              </div>
            </b-td>
            <b-td class="sf-col">{{item.sflf.away_win_rate}}</b-td>
            <b-td 
              rowspan="2" 
              class="spread-col"
              :class="[{'spread-red': Number(item.sflf.home_point_spread) > 0}, 
                      {'spread-green': Number(item.sflf.home_point_spread) < 0}]">
              {{item.sflf.home_point_spread}}
            </b-td>
            <b-td class="spreadbet-col">{{item.sflf.home_win_rate}}</b-td>
            <b-td rowspan="2" class="total-col">总分<br>{{item.dsf.total_score || '-'}}</b-td>
            <b-td class="ds-col">
              <div class="flex flex-around">
                <span class="red">大</span>
                <div class="text-right">{{item.dsf.big_rate}}</div>
              </div>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="score-col">
              <div class="flex flex-around">
                <span class="red">主</span>
                <div class="text-right">{{item.home_team_score || '-'}}</div>
              </div>
            </b-td>
            <b-td>{{item.sflf.away_lose_rate}}</b-td>
            <b-td>{{item.sflf.home_lose_rate}}</b-td>
            <b-td class="ds-col">
              <div class="flex flex-around">
                <span class="red">小</span>
                <div class="text-right">{{item.dsf.small_rate}}</div>
              </div>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="6" class="sfc-col">
              <b-table-simple
                class="sfc-table"
                fixed>
                <b-thead>
                  <b-tr>
                    <b-th>胜分差</b-th>
                    <b-th
                      v-for="(subhead, index) in sfc_table_header"
                      :key="subhead + index">
                      {{subhead}}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-th class="sfc-header">客胜</b-th>
                    <b-td>{{item.sfc.away_1_5_rate}}</b-td>
                    <b-td>{{item.sfc.away_6_10_rate}}</b-td>
                    <b-td>{{item.sfc.away_11_15_rate}}</b-td>
                    <b-td>{{item.sfc.away_16_20_rate}}</b-td>
                    <b-td>{{item.sfc.away_20_25_rate}}</b-td>
                    <b-td>{{item.sfc.away_26plus_rate}}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th class="sfc-header">主胜</b-th>
                    <b-td>{{item.sfc.home_1_5_rate}}</b-td>
                    <b-td>{{item.sfc.home_6_10_rate}}</b-td>
                    <b-td>{{item.sfc.home_11_15_rate}}</b-td>
                    <b-td>{{item.sfc.home_16_20_rate}}</b-td>
                    <b-td>{{item.sfc.home_20_25_rate}}</b-td>
                    <b-td>{{item.sfc.home_26plus_rate}}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'sport'],
  data () {
    return {
      result: null,
      fields: {
        serialno: '场次',
        league: '赛事', 
        playtime: '比赛时间', 
        team: '主场', 
        score: '比分', 
        sf: '胜负', 
        spread: '让分', 
        spreadbet: '让分投注区', 
        dsbet: '大小分投注区'
      },
      sfc_table_header: ['1～5', '6～10', '11~15', '16~20', '21~25', '26+']
    }
  },
  computed: {
    tableData: {
      get () {
        return this.result
      },
      set (data) {
        this.result = data
      }
    }
  },
  created () {
    this.tableData = this.data
  },
  watch: {
    data (cur, prev) {
      if (cur !== prev) 
        this.tableData = this.data
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>