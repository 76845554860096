<template>
  <div class="main-view-container">
    <div class="carousel-container">
      <Carousel />
    </div>
    <div class="main-info-container">
      <visitor-counter
        class="visitor-counter"
        :number="servePeople" />
      <lottery-result class="lottery-result" />
    </div>
  </div>
</template>

<script>
// import { servePeople } from '@/utils/fakeData'
import Carousel from './Carousel'
import VisitorCounter from './VisitorCounter'
import LotteryResult from './LotteryResult'
import { getVisits } from '@/api/client/member'
import { getUuid } from '@/utils/uuid'

export default {
  name: 'mainView',
  components: {
    Carousel,
    VisitorCounter,
    LotteryResult
  },
  data () {
    return {
      servePeopleQuery: {
        to: 'site',
        uuid: getUuid()
      },
      servePeople: 0
    }
  },
  // computed: {
  //   servePeople: {
  //     get () {
  //       return servePeople()
  //     }
  //   }
  // },
  methods: {
    init () {
      getVisits(this.servePeopleQuery)
        .then(res => {
          this.servePeople = res
        })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.main-view-container {
  display: flex;
  justify-content: center;
  border: 0px solid;
  border-radius: 5px;
  overflow: hidden;
  .main-info-container {
    flex-grow: 99;
    background: linear-gradient(180deg, #001339 0%, #001339 100%);
  }
}
@media (max-width: 575.98px) {
  .main-view-container {
    height: auto;
    min-height: 49.26vw;
    border-radius: 0;
    .carousel-container {
      width: 100vw;
      min-width: 100vw;
      height: inherit;
    }
    .main-info-container {
      display: none;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-view-container {
    height: auto;
    min-height: 49.26vw;
    border-radius: 0;
    .carousel-container {
      width: 100vw;
      min-width: 100vw;
      height: inherit;
      border-radius: 0;
    }
    .main-info-container {
      display: none;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-view-container {
    height: auto;
    min-height: 320px;
    // padding: 0px 120px;
    .carousel-container {
      width: 100%;
      min-width: 100%;
      height: inherit;
    }
    .main-info-container {
      display: none;
      // height: inherit;
      // display: flex;
      // flex-direction: column;
      // .lottery-result {
      //   flex-grow: 99;
      // }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-view-container {
    height: 330px;
    min-height: 330px;
    // padding: 0px 120px;
    .carousel-container {
      width: 670px;
      min-width: 670px;
      height: inherit;
    }
    .main-info-container {
      // height: inherit;
      display: flex;
      flex-direction: column;
      .lottery-result {
        flex-grow: 99;
      }
    }
  }
}
@media (min-width: 1200px) {
  .main-view-container {
    height: 400px;
    min-height: 400px;
    // padding: 0px 120px;
    .carousel-container {
      width: 812px;
      min-width: 812px;
      height: inherit;
    }
    .main-info-container {
      // height: inherit;
      display: flex;
      flex-direction: column;
      .lottery-result {
        flex-grow: 99;
      }
    }
  }
}
</style>
