import request from '@/utils/axios'

// 取得 新聞/玩法規則/贏錢技巧列表
export function getNewsList (params) {
  return request({
    url: '/api/info/news',
    method: 'get',
    params: params
  })
}

// 取得單一 新聞/玩法規則/贏錢技巧
export function getNews (id) {
  return request({
    url: `/api/info/news/${id}`,
    method: 'get'
  })
}
