export const sports = [
  {
    context: '足球',
    id: 'football',
    options: [
      {
        context: '赛事快讯',
        id: 'news',
        title: '最新足球赛事结果',
      },
      {
        context: '14场胜负彩',
        id: '14sf',
        title: '14场胜负彩',
      },
      {
        context: '任选9场',
        id: 'rx9',
        title: '任选9场',
      },
      {
        context: '6场半全',
        id: '6cb',
        title: '6场半全',
      },
      {
        context: '4场进球彩',
        id: '4cjq',
        title: '4场进球彩',
      }
    ]
  },
  {
    context: '篮球',
    id: 'baseketball',
    options: [
      {
        context: '赛事快讯',
        id: 'news',
        title: '最新篮球赛事结果',
      },
      {
        context: '胜负/让分',
        id: 'sflf',
        title: '胜负/让分',
      },
      {
        context: '胜分差',
        id: 'sfc',
        title: '胜分差',
      },
      {
        context: '大小分',
        id: 'dsf',
        title: '大小分',
      },
      {
        context: '混合过关',
        id: 'hhqq',
        title: '混合过关',
      }
    ],
    subtype: [
      {
        context: '过关',
        id: 'qg'
      },
      {
        context: '单关',
        id: 'dg'
      }
    ]
  }
]

//malay (馬來盤) / china(中國盤) / 美國盤(american) / decimal (歐洲盤) / indonesian (印尼盤)

export const sportDetailsInfo = [
  {
    key: 'football',
    context: '足球',
    type: [
      {
        key: '1.独赢＆让求＆大小盘',
        value: 'dilcds'
      }
    ],
    betting: [
      {
        key: '欧洲盘',
        value: 'decimal'
      },
      {
        key: '中国盘',
        value: 'china'
      },
      {
        key: '马来盘',
        value: 'malay'
      },
      {
        key: '印尼盘',
        value: 'indonesian'
      },
      {
        key: '美国盘',
        value: 'american'
      }
    ],
    league: [],
    sort: [
      {
        key: '按时间排序',
        value: 'date'
      },
      {
        key: '按联盟排序',
        value: 'normal'
      }
    ]
  },
  {
    key: 'baseketball',
    context: '篮球',
    type: [
      {
        key: '1.让求＆大小盘',
        value: 'lcds'
      }
    ],
    betting: [
      {
        key: '欧洲盘',
        value: 'decimal'
      },
      {
        key: '中国盘',
        value: 'china'
      },
      {
        key: '马来盘',
        value: 'malay'
      },
      {
        key: '印尼盘',
        value: 'indonesian'
      },
      {
        key: '美国盘',
        value: 'american'
      }
    ],
    league: [],
    sort: [
      {
        key: '按时间排序',
        value: 'date'
      },
      {
        key: '按联盟排序',
        value: 'normal'
      }
    ]
  }
]