<template>
  <div class="result-container">
    <div class="info">
      <div class="name">{{ result.lotteryname }}</div>
      <div class="date">{{ issue }}期 {{ getMonth(openDate) }}-{{ getDate(openDate) }} 週{{ getZhWeekday(openDate) }}</div>
    </div>
    <div class="result">
      <div class="numbers">
        <ul>
          <li
            v-for="(number, idx) in codeNums"
            :key="idx + number">
            {{ fillNum(number) }}
          </li>
        </ul>
      </div>
      <router-link :to="getLotHistoryTo">历史</router-link>
    </div>
  </div>
</template>

<script>
// import { getLotWinNum } from '@/api/client/lot'
import thumbnailDemo from '@/components/home/LotThumbnail'
import { getMonth, getDate, getZhWeekday } from '@/utils/time'
import { fillDigit } from '@/utils/string'
import { getLotTabById } from '@/utils/lot'

export default {
  name: 'mainLotResult',
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      query: {
        code: this.result.lotterycode
      },
      response: {},
      lotStatus: 'open', // open, continue, close, error
      timeInterval: null,
      newLotInterval: null
    }
  },
  computed: {
    ...thumbnailDemo.computed,
    openDate: {
      get () {
        if (
          !this.resAwards ||
          !this.resAwards[0] ||
          !this.resAwards[0].opendate
        ) {
          return new Date()
        }
        return new Date(this.resAwards[0].opendate.split(' ')[0])
      }
    },
    getLotHistoryTo: {
      get () {
        return {
          name: 'LotteryResult',
          query: {
            id: this.result.lotterycode,
            tab: getLotTabById(this.result.lotterycode).id
          }
        }
      }
    }
  },
  methods: {
    ...thumbnailDemo.methods,
    getMonth (date) {
      return getMonth(date)
    },
    getDate (date) {
      return getDate(date)
    },
    getZhWeekday (date) {
      return getZhWeekday(date)
    },
    fillNum (num) {
      return fillDigit(num, 2, '0')
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.result-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-evenly;
  .info {
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
  }
  .result {
    display: flex;
    justify-content: space-between;
    .numbers {
      ul {
        display: inline-flex;
        li {
          color: #FFFFFF;
          background: #2D9CDB;
          justify-content: center;
          align-items: center;
        }
      }
    }
    a {
      color: #F2994A;
    }
  }
}
@media (max-width: 575.98px) {}
@media (min-width: 576px) and (max-width: 767.98px) {}
@media (min-width: 768px) and (max-width: 991.98px) {
  .result-container {
    width: 230px;
    // gap: 7px;
    .info {
      margin-bottom: 7px;
      .name {
        font-weight: 500;
        font-size: 12px;
      }
      .date {
        font-size: 12px;
      }
    }
    .result {
      .numbers {
        width: 200px;
        ul {
          // gap: 3px;
          display: flex;
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          li {
            font-size: 12px;
            font-weight: 500;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius: 50%;
            margin-right: 3px;
            &:last-child{margin-right: 0;}
          }
        }
      }
      a {
        font-size: 12px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .result-container {
    width: 300px;
    // gap: 7px;
    .info {
      margin-bottom: 7px;
      .name {
        font-weight: 500;
        font-size: 13px;
      }
      .date {
        font-size: 13px;
      }
    }
    .result {
      flex-wrap: wrap;
      .numbers {
        width: 270px;
        ul {
          // gap: 3px;
          display: flex;
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          li {
            font-size: 13px;
            font-weight: 500;
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            border-radius: 50%;
            margin-right: 3px;
            &:last-child{margin-right: 0;}
          }
        }
      }
      a {
        font-size: 14px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .result-container {
    width: 330px;
    // gap: 7px;
    .info {
      margin-bottom: 7px;
      .name {
        font-weight: 500;
        font-size: 14px;
      }
      .date {
        font-size: 14px;
      }
    }
    .result {
      .numbers {
        ul {
          // gap: 5px;
          li {
            font-size: 14px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            margin-right: 5px;
            &:last-child{margin-right: 0;}
          }
        }
      }
      a {
        font-size: 14px;
      }
    }
  }
}
</style>
