<template>
  <div class="lot-nav-container">
    <div class="title" :class="formChart ? 'blue-title' : ''">
      <h2 :style="titleSize ? `font-size: ${titleSize}px` : ''">{{ title }}</h2>
    </div>
    <div class="list" :class="[(border !== '' ? `border-${border}` : ''), (shadow ? 'shadow' : '')]">
      <ul>
        <li
          v-for="(tab, idx) in allLots"
          :key="tab.id"
          @click="handleChangeTab(tab, idx)"
          :class="isActive(tab) ? 'active' : ''">
          {{ tab.context }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { allLots } from '@/constants/lots'
import { isMobile } from '@/utils/device'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    curTab: {
      type: String,
      required: true
    },
    titleSize: {
      type: Number
    },
    border: {
      type: String,
      default: ''
    },
    shadow: {
      type: Boolean,
      default: false
    },
    formChart: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allLots: allLots
    }
  },
  methods: {
    isActive (tab) {
      return this.curTab === tab.id
    },
    handleChangeTab (tab, idx) {
      this.$emit('handleChangeTab', tab)
      if (isMobile()) {
        document.querySelector('.lot-nav-container .list').scroll({
          left: idx * 60,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lot-nav-container {
  background-color: #ffffff;
  width: inherit;
  z-index: 10;
  position: relative;
  .title {
    text-align: left;
    border-bottom: 1px solid #E0E0E0;
    h2 {
      color: #000000;
    }
  }
  .list {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    &.border-top {
      border-top: 1px solid #E0E0E0;
      margin-top: 15px;
    }
    &.border-bottom {
      border-bottom: 1px solid #E0E0E0;
      margin-bottom: 10px;
    }
    &.border-top-bottom {
      border-top: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;
      margin: 15px 0 10px;
    }
    &.border-left {border-left: 1px solid #E0E0E0;}
    &.border-right {border-left: 1px solid #E0E0E0;}
    &.shadow {box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1)!important;}
    ul {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      li {
        color: #000000;
        cursor: pointer;
      }
      .active {
        color: #FFFFFF;
        background: linear-gradient(77.4deg, #0053FF 3.38%, #579FFF 91.23%);
      }
    }
  }
}
@media (max-width: 575.98px) {
  .lot-nav-container {
    max-width: 100vw;
    border-radius: 5px 5px 0px 0px;
    .title {
      box-sizing: border-box;
      border-bottom: none;
      padding: 20px 15px 0;
      h2 {
        font-weight: 500;
        font-size: 16px;
      }
    }
    .blue-title{
      background-color: #56CCF2;
      text-align: center;
      padding: 18px 0;
      h2 {
        font-size: 21px;
        line-height: 30px;
        color: #FFFFFF;
      }
    }
    .list {
      width: 100%;
      padding: 15px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      ul {
        display: inline-flex;
        transition: transform .4s ease-in;
        li {
          width: max-content;
          font-size: 13px;
          padding: 7px 10px;
          margin: 0 2px;
          &:first-child{
            margin-left: 0px;
          }
        }
        .active {
          border-radius: 25px;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lot-nav-container {
    max-width: 100vw;
    border-radius: 5px 5px 0px 0px;
    .title {
      box-sizing: border-box;
      border-bottom: none;
      padding: 20px 15px 0;
      h2 {
        font-weight: 500;
        font-size: 16px;
      }
    }
    .blue-title{
      background-color: #56CCF2;
      text-align: center;
      padding: 18px 0;
      h2 {
        font-size: 21px;
        line-height: 30px;
        color: #FFFFFF;
      }
    }
    .list {
      width: 100%;
      padding: 15px;
      overflow-x: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      ul {
        display: inline-flex;
        transition: transform .4s ease-in;
        li {
          width: max-content;
          font-size: 14px;
          padding: 8px 12px;
          margin: 0 3px;
          &:first-child{
            margin-left: 0px;
          }
        }
        .active {
          border-radius: 25px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-nav-container {
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px 4px 16px -8px #dddddd;
    .title {
      box-sizing: border-box;
      padding: 24px 0 17px 24px;
      h2 {
        font-weight: 500;
        font-size: 18px;
      }
    }
    .list {
      padding: 20px 15px 22px;
      ul {
        li {
          font-size: 16px;
          padding: 7px 10px;
        }
        .active {
          border-radius: 25px;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lot-nav-container {
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px 4px 18px -9px #dddddd;
    .title {
      box-sizing: border-box;
      padding: 27px 0 19px 27px;
      h2 {
        font-weight: 500;
        font-size: 20px;
      }
    }
    .list {
      padding: 23px 30px 25px;
      ul {
        li {
          font-size: 18px;
          padding: 7px 15px;
        }
        .active {
          border-radius: 25px;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .lot-nav-container {
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px 5px 20px -10px #dddddd;
    .title {
      box-sizing: border-box;
      padding: 30px 0 21px 30px;
      h2 {
        font-weight: 500;
        font-size: 21px;
      }
    }
    .list {
      padding: 25px 65px 28px;
      ul {
        justify-content: space-between;
        li {
          font-size: 18px;
          padding: 7px 18px;
        }
        .active {
          border-radius: 25px;
        }
      }
    }
  }
}
</style>
