<template>
  <div 
    class="table-view"
    :class="`${sport}-table-view`">
    <div
      v-for="(item, idx) in result"
      :key="item + idx"
      class="table-section">
      <b-table-simple
        bordered>
        <b-thead
          v-if="idx === 0">
          <b-tr>
            <b-th 
              v-for="field in fields"
              :key="field.key"
              :class="`${field.key}-col`">
              <span v-if="field.key === 'bet'" v-html="field.label"></span>
              <span v-else>{{field.label}}</span>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(row, i) in item"
            :key="i + row">
            <b-td
              v-for="(col, key) in row" 
              v-show="i % 2 == 0 || i % 2 !== 0 && (key !== 'league' && key !== 'play_at')"
              :class="`${key}-col`"
              :rowspan="i % 2 == 0 && (key == 'league' || key == 'play_at') ? 2 : 1"
              :key="key + col">
              <div v-if="key === 'play_at'" v-html="col"></div>
              <div v-else>{{col}}</div>
            </b-td>
            <b-td class="bet-col">
              <div class="flex">
                <div>0</div>
                <div>1</div>
                <div>2</div>
                <div>3+</div>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'sport'],
  data () {
    return {
      result: null,
      fields: [
        { key: 'serialno', label: '场次', tdClass: 'serialno-col', thClass: 'serialno-col'},
        { key: 'league', label: '赛事', tdAttr: {rowspan: 2}, tdClass: 'league-col', thClass: 'league-col'},
        { key: 'play_at', label: '比赛时间', tdAttr: {rowspan: 2}, tdClass: 'play_at-col', thClass: 'play_at-col'},
        { key: 'team', label: '对阵', tdClass: 'team-col', thClass: 'team-col'},
        { key: 'score', label: '进球数', tdClass: 'score-col', thClass: 'score-col'},
        { key: 'bet', label: '下注金额试算器<br>(投注试算功能即将更新)'}
        // { key: 'bet', label: '下注金额试算器'},
      ],
      resultCode: {
        0: '主負',
        1: '平手',
        3: '主胜'
      }
    }
  },
  computed: {
    tableData: {
      get () {
        return this.result
      },
      set (data) {
        let arr = []
        if (data) {
          arr = data.map(item => {
            return this.handleData(item)
          })
        }
        this.result = arr
      }
    }
  },
  created () {
    this.tableData = this.data
  },
  watch: {
    data (cur, prev) {
      if (cur !== prev) 
        this.tableData = this.data
    }
  },
  methods: {
    handleData (obj) {
      let arr = []
      for(let i = 0; i < 2; i++) {
        let serial = this.serialNum(obj.serialno)
        let firstCol = i % 2 === 0
        let score = firstCol ? obj.host : obj.guest
        arr.push({
          serialno: serial[i],
          league: obj.league,
          play_at: obj.play_at,
          team: firstCol ? obj.home_team : obj.away_team,
          score: score.home_team_score || score.away_team_score || '－'
        })
      }
      return arr
    },
    serialNum (str) {
      return str.split('-')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>