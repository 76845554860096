<script>
import demo from './Template/index'

export default {
  ...demo,
  data () {
    return {
      ...demo.data(),
      tab: 'LotNation'
    }
  },
  computed: {
    ...demo.computed,
    content: {
      get () {
        return [
          // 福彩3D
          this.tabLots
            .filter(item => item.id === 'FC3D')[0],
          // 体彩P3
          this.tabLots
            .filter(item => item.id === 'TCPL3')[0],
          // 体彩排列5
          this.tabLots
            .filter(item => item.id === 'TCPL5')[0],
          // 香港六合彩
          this.tabLots
            .filter(item => item.id === 'XGLHC')[0]
        ]
      }
    }
  }
}
</script>
