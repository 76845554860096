/*
  共用
  import {} from '@/utils/display/common'
*/

// 總和尾數（個位數）: 「尾大、尾小」
export function commonTotalLBS (n, limit) {
  const total = commonTotal(n)
  if (total % 10 >= limit) {
    return '尾大'
  }
  return '尾小'
}

// 總和大小
// export function commonTotalBS (n, limit) {
//   const total = commonTotal(n)
//   if (total >= limit) {
//     return '大'
//   }
//   return '小'
// }

// 總和單雙
// export function commonTotalOE (n) {
//   const total = commonTotal(n)
//   if (total % 2 === 0) {
//     return '双'
//   }
//   return '单'
// }

// 大小
export function commonBS (n, limit) {
  if (n >= limit) {
    return '大'
  }
  return '小'
}

// 單雙
export function commonOE (n) {
  if (n % 2 === 0) {
    return '双'
  }
  return '单'
}

// 總和: 所有彩票數字相加
export function commonTotal (n) {
  const newN = n.reduce((ac, curVal) => {
    return ac + Number(curVal)
  }, 0)
  return newN
}

// 取得指定 position 前中後三位數
export function commonCodeNums (n, position) {
  var newN = arrStrToNum(n)
  var f30, f31, f32
  if (position === 'f') {
    f30 = newN[0]
    f31 = newN[1]
    f32 = newN[2]
  } else if (position === 'm') {
    f30 = newN[1]
    f31 = newN[2]
    f32 = newN[3]
  } else if (position === 'l') {
    f30 = newN[2]
    f31 = newN[3]
    f32 = newN[4]
  }
  return [f30, f31, f32]
}

// 轉數字
// export function strToNum (n) {
//   if (typeof (n) !== 'number') {
//     return Number(n)
//   }
// }

// Array字串轉數字
export function arrStrToNum (arr) {
  return arr.map((i) => Number(i))
}
