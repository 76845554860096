<template>
  <div class="lot-news-container">
    <ul class="lot-news-list">
      <li
        v-for="(item, idx) in news"
        :key="item.id + item.title">
        <!-- <div class="news-photo" :style="{'background-image': 'url(' + item.photo + ')'}"></div> -->
        <div v-if="isMobile" class="image-container">
          <div class="image-box">
            <!-- <i class="el-icon-picture"></i> -->
            <img :src="defaultImage" v-real-img="getPhotoUrl(item)" />
          </div>
        </div>
        <div class="main-container">
          <div class="date-container">
            <div
              class="date-first"
              v-if="idx === 0 && !isMobile">
              <div>{{ getDateDay(item) }}</div>
              <div>{{ getDateDate(item) }}</div>
            </div>
            <div
              class="date-others"
              v-else>
              {{ confirmDate(item) }}
            </div>
          </div>
          <div class="content-container" >
            <router-link
              :class="getClass(idx)"
              :to="{ name: 'LotteryInfoDetail', query: { id: item.id,type: pagetype,num: 5 }}"
              @click.native="sendVisit(item.id)">{{ item.title }}
            </router-link>
            <div
              class="content"
              v-if="idx === 0 && !isMobile"
              v-html="item.content">
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { visit } from '@/api/client/visit'
import { contentLimit } from '@/utils/contentLimit'
import { isMobile } from '@/utils/device'

export default {
  name: 'lotNews',
  // 首頁與彩票資訊共用，用props控制呈現模式
  props: {
    content: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      pagetype: 'news',
      visitQuery: {
        to: '',
        uuid: '',
        id: ''
      }
    }
  },
  computed: {
    news: {
      get () {
        return isMobile() ? (this.content.length > 5 ? this.content.slice(0, -1) : this.content) : contentLimit(this.content)
      }
    },
    isMobile () {
      return isMobile()
    },
    defaultImage () {
      return require(`@/assets/share-default.jpg`)
    }
  },
  methods: {
    sendVisit (id) {
      this.visitQuery.to = this.pagetype === 'trick' ? 'winskill' : this.pagetype
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    getClass (idx) {
      if (idx === 0 && !isMobile()) {
        return 'home-title-first'
      } else {
        return 'home-title'
      }
    },
    confirmDate (item) {
      return item.showdate ? item.showdate : item.start_at ? item.start_at.split(' ')[0] : item.created_at.split(' ')[0]
    },
    getDateDay (item) {
      let date = this.confirmDate(item)
      return date.slice(8,10)
    },
    getDateDate (item) {
      let date = this.confirmDate(item)
      return date.slice(0,7)
    },
    getPhotoUrl (item) {
      return item.photo !== '' ? item.photo : item.photo_u !== "" ? item.photo_u : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.lot-news-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 30px;
  > :not(:first-child) {
    border-top: 1px solid #E0E0E0;
  }
  li {
    .main-container {
      display: flex;
      padding: 15px 0;
      // gap: 25px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .date-container {
        width: max-content;
        margin-right: 25px;
        .date-first {
          display: flex;
          width: 90px;
          height: 90px;
          border-radius: 8px;
          flex-direction: column;
          justify-content: center;
          margin: 10px 0;
          background: #0053FF;
          :first-child {
            color: #FFD600;
          }
          :not(:first-child) {
            color: #FFFFFF;
          }
        }
        .date-others {
          color: #0053FF;
        }
      }
      .content-container {
        letter-spacing: 0em;
        text-align: left;
        font-style: normal;
        .home-title-first {
          color: #333333;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          margin-bottom: 14px;
        }
        .home-title-first:hover {
          color: #005FDE;
        }
        .home-title {
          color: #333333;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
        .home-title:hover {
          color: #005FDE;
        }
        .content {
          color: #000000;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          word-break: break-word;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
.lot-news-list:first-child {
  border-top: none;
}
@media (max-width: 575.98px) {
  .lot-news-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    > :not(:first-child) {
      border: none;
    }
    > :not(:last-child) {
      border: none;
      border-bottom: 1px solid #E0E0E0;
    }
    li {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      padding: 15px 0;
      // gap: 13px;
      // .news-photo {
      //   width: 100px;
      //   min-width: 100px;
      //   height: 70px;
      //   background-size: cover;
      //   border-radius: 3px;
      //   margin-right: 13px;
      // }
      .image-container{
        .image-box {
          width: 100px;
          height: 70px;
          background-color: #f0f3f7;
          border-radius: 3px;
          overflow: hidden;
          margin-right: 13px;
          img {
            width: inherit;
            height: inherit;
            object-fit: cover;
          }
        }
      }
      .main-container{
        display: flex;
        height: 70px;
        max-height: 70px;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: space-between;
        padding: 3px 0;
        // gap: inherit;
        .date-container {
          font-size: 13px;
          line-height: 16px;
          .date-first {
            display: none;
          }
          .date-others {
            color: #BDBDBD;
            font-weight: 400;
          }
        }
        .content-container {
          .home-title {
            display: -webkit-box;
            overflow : hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #333333;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
          }
          .home-title:hover {
            color: #005FDE;
          }
        }
      }
    }
  }
  .lot-news-list:first-child {border-top: none;}
  .lot-news-list:last-child {border-bottom: none;}
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lot-news-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    > :not(:first-child) {
      border: none;
    }
    > :not(:last-child) {
      border: none;
      border-bottom: 1px solid #E0E0E0;
    }
    li {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      padding: 15px 0;
      // gap: 13px;
      // .news-photo {
      //   width: 100px;
      //   min-width: 100px;
      //   height: 70px;
      //   background-size: cover;
      //   border-radius: 3px;
      //   margin-right: 13px;
      // }
      .image-container{
        .image-box {
          width: 100px;
          height: 70px;
          background-color: #f0f3f7;
          border-radius: 3px;
          overflow: hidden;
          img {
            width: inherit;
            height: inherit;
            object-fit: cover;
          }
        }
      }
      .main-container{
        display: flex;
        height: 70px;
        max-height: 70px;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: space-between;
        padding: 3px 0;
        // gap: inherit;
        .date-container {
          font-size: 13px;
          line-height: 16px;
          .date-first {
            display: none;
          }
          .date-others {
            color: #BDBDBD;
          }
        }
        .content-container {
          .home-title {
            display: -webkit-box;
            overflow : hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #333333;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
          }
          .home-title:hover {
            color: #005FDE;
          }
        }
      }
    }
  }
  .lot-news-list:first-child {border-top: none;}
  .lot-news-list:last-child {border-bottom: none;}
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-news-list {
    li {
      .image-container{
        margin-right: 20px;
      }
      .main-container{
        .date-container {
          min-width: 90px;
          .date-first {
            :first-child {
              font-size: 48px;
              margin-bottom: -5px;
            }
            :not(:first-child) {
              font-size: 18px;
            }
          }
          .date-others {
            font-size: 16px;
          }
        }
        .content-container {
          flex-grow: 99;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lot-news-list {
    li {
      .main-container{
        .date-container {
          min-width: 90px;
          .date-first {
            :first-child {
              font-size: 48px;
              margin-bottom: -5px;
            }
            :not(:first-child) {
              font-size: 18px;
            }
          }
          .date-others {
            font-size: 16px;
          }
        }
        .content-container {
          flex-grow: 99;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .lot-news-list {
    li {
      .main-container{
        .date-container {
          min-width: 90px;
          .date-first {
            :first-child {
              font-size: 48px;
              margin-bottom: -5px;
            }
            :not(:first-child) {
              font-size: 18px;
            }
          }
          .date-others {
            font-size: 16px;
          }
        }
        .content-container {
          flex-grow: 99;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
