<template>
  <div class="lot-lobby">
    <lot-nav-bar
      title="彩票大厅"
      :curTab="curTab"
      @handleChangeTab="handleChangeTab" />
    <div class="lobby-content">
      <transition name="lot-lobby">
        <keep-alive>
          <component :is="curTab" />
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import LotNavBar from '@/components/LotNavBar'
import LotHH from './contents/LotHH'
import LotPK10 from './contents/LotPK10'
import Lot11P5 from './contents/Lot11P5'
import LotQ3 from './contents/LotQ3'
import Happy81012 from './contents/Happy81012'
// import LotMM from './contents/LotMM'
import LotNation from './contents/LotNation'
import LotOversea from './contents/LotOversea'
import LotOther from './contents/LotOther'

export default {
  name: 'lotLobby',
  components: {
    LotNavBar,
    LotHH,
    LotPK10,
    Lot11P5,
    LotQ3,
    Happy81012,
    // LotMM,
    LotNation,
    LotOversea,
    LotOther
  },
  data () {
    return {
      curTab: 'LotHH'
    }
  },
  methods: {
    handleChangeTab (tab) {
      this.curTab = tab.id
    }
  }
}
</script>

<style lang="scss" scoped>
.lot-lobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  user-select:none;
  .lobby-content {
    background: #FFFFFF;
    width: inherit;
  }
}

@media (max-width: 575.98px) {}
@media (min-width: 576px) and (max-width: 767.98px) {}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-lobby {
    .lobby-content {
      height: fit-content;
      padding: 30px 20px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lot-lobby {
    .lobby-content {
      height: fit-content;
      padding: 30px 20px;
    }
  }
}
@media (min-width: 1200px) {
  .lot-lobby {
    .lobby-content {
      height: fit-content;
      padding: 30px 20px;
    }
  }
}
</style>
