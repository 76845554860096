<template>
  <div class="thumbnail-container">
    <!-- @changeNextOpen="handleChangeNextOpen" -->
    <lot-head
      :lotInfo="lotInfo"
      :response="response"
      :lotStatus="lotStatus"
      :nextOpen="nextOpen" />
    <lot-num
      :id="lotInfo.id"
      :codeNums="codeNums"
      :plusCodeNum="plusCodeNum" />
    <lot-display
      :id="lotInfo.id"
      :displayItem="displayResult"
      :codeNums="codeNums" />
    <div class="lot-link">
      <div class="divide"></div>
      <lot-link
        :displayItem="displayLink"
        :id="lotInfo.id" />
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { getLotWinNum } from '@/api/client/lot'
import LotHead from './LotHead'
import LotNum from './LotNum'
import LotDisplay from './LotDisplay'
import LotLink from './LotLink'
import { isEmpty } from 'lodash'
import { dateFormat } from '@/utils/time'

export default {
  props: {
    lotInfo: {
      type: Object,
      required: true
    },
    componentActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    LotHead,
    LotNum,
    LotDisplay,
    LotLink
  },
  data () {
    return {
      query: {
        code: this.lotInfo.id
      },
      response: {},
      lotStatus: 'open', // open, continue, close, error
      // timeInterval: null,
      // newLotInterval: null,
      countDownInterval: null,
      dateNow: null,
      reloadTime: 5000,
      isReload: true
      // reloadInterval: null
    }
  },
  computed: {
    resAwards: {
      get () {
        if (!this.response.awards) {
          return []
        }
        return this.response.awards
      }
    },
    resLot: {
      get () {
        if (!this.response.lottery) {
          return {}
        }
        return this.response.lottery
      }
    },
    issue: {
      get () {
        if (!this.resLot.last_issue) {
          return ' '
        }
        return this.resLot.last_issue
      }
    },
    codeNums: {
      get () {
        const curLot = this.resAwards.filter(lot => {
          return lot.issue === this.issue
        })[0]
        if (!curLot) {
          return [0, 0, 0, 0, 0]
        }
        return curLot.code.split('+')[0].split(',')
      }
    },
    plusCodeNum: {
      get () {
        const curLot = this.resAwards.filter(lot => {
          return lot.issue === this.issue
        })[0]
        if (!curLot) {
          return null
        }
        const plusNumSplit = curLot.code.split('+')
        if (plusNumSplit.length === 2) {
          return Number(plusNumSplit[1])
        }
        return null
      }
    },
    displayResult: {
      get () {
        if (!this.lotInfo.rule || !this.lotInfo.rule.display) {
          return {}
        }
        return this.lotInfo.rule.display
      }
    },
    displayLink: {
      get () {
        if (!this.lotInfo.rule.link) {
          return {}
        }
        return this.lotInfo.rule.link
      }
    },
    nextOpen: {
      get () {
        if (
          this.lotNextDate &&
          this.dateNow
        ) {
          if (Math.floor((this.lotNextDate - this.dateNow) / 1000) < 0) {
            return 0
          }
          return Math.floor((this.lotNextDate - this.dateNow) / 1000)
        }
        return 0
      }
    },
    // nextOpen: {
    //   get () {
    //     if (!this.resLot.next_open_remaining) {
    //       return 0
    //     }
    //     return this.resLot.next_open_remaining
    //   },
    //   set (num) {
    //     if (!this.response.lottery) {
    //       this.response.lottery = {}
    //     }
    //     this.response.lottery.next_open_remaining = num
    //   }
    // },
    lotNextDate: {
      set (val) {
        if (this.response.lottery) {
          this.response.lottery.next_opendate = val
        }
      },
      get () {
        if (isEmpty(this.response)) {
          return null
        }
        const nextOpenTime = this.response.lottery.next_opendate
        if (typeof nextOpenTime === 'string') {
          const time = nextOpenTime.replace(/\s/, 'T')
          return new Date(time)
        } else {
          return null
        }
      }
    }
  },
  methods: {
    init () {
      this.getLotWinNum()
        .then(res => {
          if (
            res !== 0 &&
            this.isReload &&
            this.componentActive
          ) {
            this.setReload()
          }
        })
    },
    getLotWinNum () {
      return getLotWinNum(this.query)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              if (
                // first load data
                isEmpty(this.response) ||
                // get new data
                this.isNewData(res)
              ) {
                this.saveData(res)
                this.initTime()
                // this.stopReload()
                this.setLotStatus(res)
                return new Promise(resolve => {
                  resolve(0)
                })
              } else {
                return new Promise(resolve => {
                  resolve(404)
                })
              }
              // this.checkAndSaveData(res)
              // this.lotStatus = this.response.lottery.status
              // if (this.lotStatus !== 'close') {
              //   this.countDownNextOpen()
              // }
            case resStatus.todayNotYet:
              this.lotStatus = 'close'
              return new Promise(resolve => {
                resolve(404)
              })
            case '1000':
            case '1001':
            case '1010':
              this.lotStatus = 'error'
              break
            default:
              this.lotStatus = 'error'
              console.error(`status wrong error_${this.lotInfo.id}: `, res)
          }
        })
        .catch(err => {
          console.error(`catch error_${this.lotInfo.id}: `, err)
        })
    },
    isNewData (res) {
      return this.response.lottery.last_issue !== res.data.lottery.last_issue
    },
    saveData (res) {
      this.response = res.data
    },
    initTime () {
      this.countDownInterval = setInterval(() => {
        this.dateNow = Date.now()
      }, 1000)
    },
    setReload () {
      setTimeout(() => {
        if (this.componentActive) {
          this.init()
        }
      }, this.reloadTime)
    },
    // stopReload () {
    //   clearInterval(this.reloadInterval)
    // },
    setLotStatus (res) {
      this.lotStatus = res.data.lottery.status
    },
    curTime (date) {
      const time = dateFormat('yyyy-MM-dd hh:mm:ss', date)
      return time
      // return dateFormat('yyyy-MM-dd hh:mm:ss', date)
    },
    stopCountdown (val) {
      clearInterval(this.countDownInterval)
      if (val) {
        this.lotNextDate = val
      }
    },
    emptyResponse () {
      this.response = {}
    }
    // checkAndSaveData (res) {
    //   this.response = res.data
    //   if (this.newLotInterval) {
    //     this.clearNewLotInterval()
    //   }
    // },
    // handleChangeNextOpen (num) {
    //   this.nextOpen = num
    // },
    // countDownNextOpen () {
    //   this.timeInterval = setInterval(() => {
    //     this.nextOpen -= 1
    //     if (this.nextOpen <= 0) {
    //       this.nextOpen = 0
    //       clearInterval(this.timeInterval)
    //       this.lotStatus = 'continue'
    //       this.getNewLotResult()
    //     }
    //   }, 1000)
    // },
    // getNewLotResult () {
    //   this.newLotInterval = setInterval(() => {
    //     // console.log('get new lot')
    //     this.init()
    //     this.clearNewLotInterval()
    //   }, 3000)
    // },
    // clearNewLotInterval () {
    //   clearInterval(this.newLotInterval)
    // }
  },
  created () {
    // console.log('created init!')
    // this.init()
  },
  destroyed () {
    this.isReload = false
  },
  watch: {
    dateNow (val) {
      if (this.curTime(val) >= this.curTime(this.lotNextDate)) {
        this.stopCountdown(val)
        this.init()
      }
    },
    componentActive (val) {
      if (!val) {
        this.stopCountdown()
        this.emptyResponse()
      } else {
        this.init()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #D7D7D7;
.thumbnail-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
  .lot-head {
    display: flex;
    flex-direction: column;
  }
  .lot-link {
    .divide {
      width: 100%;
      border-top: 1px solid $border-color;
    }
  }
}
@media (max-width: 575.98px) {
  .thumbnail-container {
    height: max-content;
    border-radius: 5px;
    padding: 15px 15px 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border: none;
    .lot-link {
      .divide {
        margin-bottom: 10px;
        border-top: none;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .thumbnail-container {
    height: max-content;
    border-radius: 5px;
    padding: 15px 15px 5px;
    border: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    .lot-link {
      .divide {
        margin-bottom: 10px;
        border-top: none;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .thumbnail-container {
    // height: 380px;
    min-height: 380px;
    height: max-content;
    border-radius: 5px;
    padding: 25px 15px 10px;
    .lot-link {
      .divide {
        margin-bottom: 10px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .thumbnail-container {
    // height: 380px;
    min-height: 380px;
    height: max-content;
    border-radius: 5px;
    padding: 25px 15px 10px;
    .lot-link {
      .divide {
        margin-bottom: 10px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .thumbnail-container {
    // height: 380px;
    min-height: 380px;
    height: max-content;
    border-radius: 5px;
    padding: 25px 15px 10px;
    .lot-link {
      .divide {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
