<script>
import demo from '../LotNews/index'
import { visit } from '@/api/client/visit'
// import { fakeTrick } from '@/constants/fakeTrick'
import { contentLimit } from '@/utils/contentLimit'
import { isMobile } from '@/utils/device'

export default {
  ...demo,
  // computed: {
  //   news: {
  //     get () {
  //       return isMobile() ? (this.content.length > 5 ? this.content.slice(0, -1) : this.content) : contentLimit(this.content)
  //     }
  //   },
  //   isMobile () {
  //     return isMobile()
  //   }
  // },
  data () {
    return {
      pagetype: 'user_article',
      visitQuery: {
        to: '',
        uuid: '',
        id: ''
      }
    }
  },
  // methods: {
  //   sendVisit (id) {
  //     this.visitQuery.to = this.pagetype === 'trick' ? 'winskill' : this.pagetype
  //     this.visitQuery.id = id
  //     visit(this.visitQuery)
  //   },
  //   getClass (idx) {
  //     if (idx === 0 && !isMobile()) {
  //       return 'home-title-first'
  //     } else {
  //       return 'home-title'
  //     }
  //   },
  //   confirmDate (item) {
  //     return item.showdate ? item.showdate : item.start_at ? item.start_at.split(' ')[0] : item.created_at.split(' ')[0]
  //   },
  //   getDateDay (item) {
  //     let date = this.confirmDate(item)
  //     return date.slice(8,10)
  //   },
  //   getDateDate (item) {
  //     let date = this.confirmDate(item)
  //     return date.slice(0,7)
  //   },
  //   getPhotoUrl (item) {
  //     return item.photo !== '' ? item.photo : item.photo_u !== "" ? item.photo_u : ''
  //   }
  // }
}
</script>
