/*
  11選5
  import {} from '@/utils/display/11P5'
*/
import { commonCodeNums, commonTotal } from '@/utils/display/common'

// 總和: 「大、小、和」
export function P5TotalBS (n) {
  const total = commonTotal(n)
  if (total >= 31 && total <= 45) {
    return '大'
  } else if (total >= 15 && total <= 29) {
    return '小'
  } else if (total === 30) {
    return '和'
  }
  return 'ERR'
}

// 1~11數字: 大、小、和
export function P5BS (n) {
  if (n >= 6 && n < 11) {
    return '大'
  } else if (n <= 5) {
    return '小'
  }
  return '和'
}

// 1~11數字: 單、雙、和
export function P5OE (n) {
  if (n === 11) {
    return '和'
  } else if (n % 2 === 0) {
    return '双'
  } else {
    return '单'
  }
}

// 五位數取前中後三位數: 「雜六、半順、順子」
// f: 前(default), m：中, l：後
export function P5Three (n, position = 'f') {
  var f = commonCodeNums(n, position)
  var sort = f.sort()

  var z = f.indexOf(0) !== -1 && f.indexOf(9) !== -1 && f.indexOf(8) !== -1
  var i = f.indexOf(0) !== -1 && f.indexOf(9) !== -1 && f.indexOf(1) !== -1
  var o = f.indexOf(0) !== -1 && f.indexOf(9) !== -1

  if (
    (
      sort[0] + 1 === sort[1] &&
      sort[1] + 1 === sort[2]
    ) || z || i
  ) {
    return '顺子'
  } else if (
    sort[0] + 1 === sort[1] ||
    sort[1] + 1 === sort[2] ||
    o
  ) {
    return '半顺'
  }
  return '杂六'
}

// 龍虎
// 第一位大於第二位為“龍”、第一位小於第二位為“虎”
export function P5DT (n) {
  const th = Number(n[0])
  const d = Number(n[1])
  if (th > d) {
    return '龙'
  }
  return '虎'
}
