<template>
  <div class="info-container">
    <div class="header">
      <div class="left">
        <h2 >彩票资讯</h2>
        <ul>
          <li
            v-for="tab in tabs"
            :key="tab.id"
            @click="handleClickTab(tab)"
            :class="isActive(tab) ? 'active' : ''">
            {{ tab.context }}
          </li>
        </ul>
      </div>
      <div class="right">
        <router-link :to="{ name: 'LotteryInfo' }">了解更多<b-icon icon="chevron-right"></b-icon></router-link>
      </div>
    </div>
    <div class="content">
      <!-- <transition name="lot-info"> -->
        <keep-alive>
          <component
            :is="curTab"
            :content="content" />
        </keep-alive>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
import { lotInfoTabs } from '@/constants/home'
import LotNews from './LotNews'
import LotTrick from './LotTrick'
import LotShare from './LotShare'
import { getNewsList } from '@/api/client/lotNews'
import { getTricksList } from '@/api/client/lotTricks'
import { getArticleList } from '@/api/client/lotShare'
import resStatus from '@/constants/resStatus'

export default {
  name: 'lotInfo',
  components: {
    LotNews,
    LotTrick,
    LotShare
  },
  data () {
    return {
      curTab: this.checkShowTab(),
      tabs: lotInfoTabs,
      query: {
        page: 1,
        pageSize: 6
      },
      content: []
    }
  },
  methods: {
    handleClickTab (tab) {
      this.curTab = tab.id
      this.updateContent()
    },
    isActive (tab) {
      return tab.id === this.curTab
    },
    checkShowTab () {
      let type = this.$route.query.type
      let typeName = ''
      switch (type) {
        case 'trick':
          typeName = 'LotTrick'
          break;
        case 'news':
          typeName = 'LotNews'
          break;
        case 'user_article':
          typeName = 'LotShare'
          break;
        default:
          typeName = 'LotNews'
      }
      return typeName
      // if (this.show === 'lotteryInfoDetail' && this.$route.query.type === 'trick') {
      //   return 'LotTrick'
      // } else {
      //   return 'LotNews'
      // }
    },
    updateContent () {
      const useFunc = this.curTab === 'LotNews' ? getNewsList : 
                    this.curTab === 'LotTrick' ? getTricksList :
                    getArticleList

      useFunc(this.query)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.content = res.data
          }
        })
      // if (this.curTab === 'LotNews') {
      //   getNewsList(this.query)
      //     .then(res => {
      //       if (res.status === resStatus.OK) {
      //         this.content = res.data
      //       }
      //     })
      // } else {
      //   getTricksList(this.query)
      //     .then(res => {
      //       if (res.status === resStatus.OK) {
      //         this.content = res.data
      //       }
      //     })
      // }
    }
  },
  created () {
    this.updateContent()
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    .left {
      display: flex;
      height: 100%;
      align-items: center;
      // gap: 64px;
      h2 {
        color: #000000;
        margin-right: 64px;
      }
      ul {
        display: flex;
        height: 100%;
        li {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: #000000;
        }
        .active {
          color: #005FDE;
          border-bottom: 3px solid #005FDE;
          padding-top: 3px;
        }
      }
    }
  }
  .content {
    flex-grow: 99;
    cursor: pointer;
  }
}
@media (max-width: 575.98px) {
  .info-container {
    background-color: #ffffff;
    padding: 20px 15px;
    .header {
      height: max-content;
      box-sizing: border-box;
      align-items: flex-start;
      .left {
        align-items: flex-start;
        flex-direction: column;
        // gap: 0;
        h2 {
          font-weight: 500;
          font-size: 16px;
        }
        ul {
          margin: 15px 0 5px;
          li {
            padding: 6px 15px;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 13px;
            color: #4F4F4F;
          }
          .active{
            background: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
            border: none;
            color: #FFFFFF;
            border-radius: 50px;
            font-weight: 500;
            padding-top: 5px;
          }
        }
      }
      .right {
        a { color: inherit; }
        color: #828282;
        font-size: 13px;
        font-style: normal;
        line-height: 18px;
        letter-spacing: 0em;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .info-container {
    background-color: #ffffff;
    padding: 20px 15px;
    .header {
      height: max-content;
      box-sizing: border-box;
      border-bottom: 1px solid #E0E0E0;
      align-items: flex-start;
      .left {
        align-items: flex-start;
        flex-direction: column;
        // gap: 0;
        h2 {
          font-weight: 500;
          font-size: 16px;
        }
        ul {
          margin: 15px 0 20px;
          li {
            padding: 6px 15px;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 13px;
            color: #4F4F4F;
          }
          .active{
            background: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
            border: none;
            color: #FFFFFF;
            border-radius: 50px;
            font-weight: 500;
            padding-top: 5px;
          }
        }
      }
      .right {
        a { color: inherit; }
        color: #828282;
        font-size: 13px;
        font-style: normal;
        line-height: 18px;
        letter-spacing: 0em;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .info-container {
    min-height: fit-content;
    background-color: #ffffff;
    border-radius: 5px;
    .header {
      height: 65px;
      box-sizing: border-box;
      padding-top: 10px;
      border-bottom: 1px solid #E0E0E0;
      .left {
        margin-left: 30px;
        // gap: 25px;
        h2 {
          font-weight: 500;
          font-size: 18px;
          margin-right: 25px;
        }
        ul {
          li {
            width: 90px;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
      .right {
        a { color: inherit; }
        margin-right: 30px;
        color: #828282;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .content{
      min-height: calc(100% - 65px);
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info-container {
    min-height: 484px;
    background-color: #ffffff;
    border-radius: 5px;
    .header {
      height: 80px;
      box-sizing: border-box;
      padding-top: 10px;
      border-bottom: 1px solid #E0E0E0;
      .left {
        margin-left: 30px;
        h2 {
          font-weight: 500;
          font-size: 21px;
        }
        ul {
          li {
            width: 100px;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .right {
        a { color: inherit; }
        margin-right: 30px;
        color: #828282;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .content{
      min-height: calc(100% - 80px);
    }
  }
}
@media (min-width: 1200px) {
  .info-container {
    min-height: 484px;
    background-color: #ffffff;
    border-radius: 5px;
    .header {
      height: 80px;
      box-sizing: border-box;
      padding-top: 10px;
      border-bottom: 1px solid #E0E0E0;
      .left {
        margin-left: 30px;
        h2 {
          font-weight: 500;
          font-size: 21px;
        }
        ul {
          li {
            width: 100px;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .right {
        a { color: inherit; }
        margin-right: 30px;
        color: #828282;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
</style>
