<template>
  <div class="hot-lot-container">
    <logo />
    <lots class="lots" />
  </div>
</template>

<script>
import Logo from './Logo'
import Lots from './Lots'

export default {
  name: 'hotLot',
  components: {
    Logo,
    Lots
  }
}
</script>

<style lang="scss" scoped>
.hot-lot-container {
  display: flex;
  height: fit-content;
  justify-content: space-between;
  background-color: #FFFFFF;
  border-radius: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  .lots {
    flex-grow: 99;
  }
}
@media (max-width: 575.98px) {
  .hot-lot-container {
    display: block;
    background-color: #FFFFFF;
    padding: 0 0 5px;
    border-radius: 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .hot-lot-container {
    display: block;
    background-color: #FFFFFF;
    padding: 0 0 5px;
    border-radius: 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {}
</style>
