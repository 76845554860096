import { allLots } from './lots'
// 热门彩种
export const hotLots = [
  // 欢乐生肖
  allLots
    .filter(item => item.id === 'LotHH')[0]
    .children
    .filter(item => item.id === 'CQSSC')[0],
  // 河内五分彩
  allLots
    .filter(item => item.id === 'LotOversea')[0]
    .children
    .filter(item => item.id === 'HN300')[0],
  // 北京PK10
  allLots
    .filter(item => item.id === 'LotPK10')[0]
    .children
    .filter(item => item.id === 'BJPK10')[0],
  // 河内分分彩
  allLots
    .filter(item => item.id === 'LotOversea')[0]
    .children
    .filter(item => item.id === 'HN60')[0],
  // 新疆时时彩
  allLots
    .filter(item => item.id === 'LotHH')[0]
    .children
    .filter(item => item.id === 'XJSSC')[0],
  // 腾讯分分彩
  allLots
    .filter(item => item.id === 'LotHH')[0]
    .children
    .filter(item => item.id === 'TXFFC')[0],
  // 皇家幸运飞艇
  allLots
    .filter(item => item.id === 'LotPK10')[0]
    .children
    .filter(item => item.id === 'XYFT')[0],
  // 江苏快三
  allLots
    .filter(item => item.id === 'LotQ3')[0]
    .children
    .filter(item => item.id === 'JSKS')[0]
]

// 彩票资讯
export const lotInfoTabs = [
  {
    context: '彩票新闻',
    id: 'LotNews'
  },
  {
    context: '彩票技巧',
    id: 'LotTrick'
  },
  {
    context: '彩友分享',
    id: 'LotShare'
  }
]
