import request from '@/utils/axios'

// 取得首頁足彩相關資訊
export function getFootballInfo (params) {
  return request({
    // url: '/api/sportlottery/football',
    url: '/api/sportlottery/zgzcw/football',
    method: 'get',
    params: params
  })
}

// 取得首頁籃彩相關資訊
export function getBaseketballInfo (params) {
  return request({
    // url: '/api/sportlottery/baseketball',
    url: '/api/sportlottery/zgzcw/baseketball',
    method: 'get',
    params: params
  })
}

// 取得聯盟列表
export function getLeagues (params) {
  return request({
    url: '/api/sportlottery/fb88/leagues',
    method: 'get',
    params: params
  })
}

// 取得足彩相關資訊
export function getDetailFootballInfo (params) {
  return request({
    url: '/api/sportlottery/fb88/football',
    method: 'get',
    params: params
  })
}

// 取得籃彩相關資訊
export function getDetailBaseketballInfo (params) {
  return request({
    url: '/api/sportlottery/fb88/baseketball',
    method: 'get',
    params: params
  })
}

export function getRecommendSportList (params) {
  return request({
    url: '/api/sportlottery/fb88/recommend',
    method: 'get',
    params: params
  })
}

export function getSportLiveStream () {
  return request({
    url: '/api/sportlottery/fb88/live',
    method: 'get'
  })
}
