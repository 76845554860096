import request from '@/utils/axios'

// 首頁開獎結果
export function getIssueResult () {
  return request({
    url: '/api/display/issue_result',
    methods: 'get'
  })
}

// 首頁熱門彩種 type=hot& 新聞技巧右方獎號預測type=predict
export function getDisplay (params) {
  return request({
    url: '/api/display/lottery',
    methods: 'get',
    params: params
  })
}
