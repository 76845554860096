<template>
  <div class="visitor-counter-container">
    <h3>累计服务人数</h3>
    <div class="counter-container">
      <div
        class="counter-num"
        v-for="(num, idx) in numArr"
        :key="idx+num">
        {{ num }}
      </div>
      <span>人</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mainVisitorCounter',
  props: {
    number: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      counterDigit: 6
    }
  },
  computed: {
    numArr: {
      get () {
        const tmpArr = this.num2Arr(this.props)
        if (tmpArr.length > this.counterDigit) {
          return '999999'.split('')
        }
        while (tmpArr.length < this.counterDigit) {
          tmpArr.unshift('0')
        }
        return tmpArr
      }
    }
  },
  methods: {
    num2Arr () {
      return `${this.number}`.split('')
    }
  }
}
</script>

<style lang="scss" scoped>
.visitor-counter-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  h3 {
    color: #EB5757;
  }
  .counter-container {
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    background: linear-gradient(342.51deg, #917225 15.18%, #BBA058 51.5%, #C8AD67 80.7%);
    .counter-num {
      color: #1A265E;
      background-color: #F3F9FF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      color: #FFFFFF;
    }
  }
}
@media (max-width: 575.98px) {}
@media (min-width: 576px) and (max-width: 767.98px) {}
@media (min-width: 768px) and (max-width: 991.98px) {
  .visitor-counter-container {
    height: 100px;
    border-bottom: 1px solid #294889;
    h3 {
      font-weight: 500;
      font-size: 16px;
    }
    .counter-container {
      width: 230px;
      // gap: 7px;
      padding: 8px 14px;
      border-radius: 5px;
      .counter-num {
        font-weight: 500;
        font-size: 24px;
        width: 31px;
        height: 28px;
        border-radius: 3px;
        margin-right: 7px;
        &:last-child{margin-right: 0;}
      }
      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .visitor-counter-container {
    height: 110px;
    border-bottom: 1px solid #294889;
    h3 {
      font-weight: 500;
      font-size: 16px;
    }
    .counter-container {
      width: 250px;
      // gap: 7px;
      padding: 8px 14px;
      border-radius: 5px;
      .counter-num {
        font-weight: 500;
        font-size: 24px;
        width: 31px;
        height: 28px;
        border-radius: 3px;
        margin-right: 7px;
        &:last-child{margin-right: 0;}
      }
      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .visitor-counter-container {
    height: 137px;
    border-bottom: 1px solid #294889;
    h3 {
      font-weight: 500;
      font-size: 18px;
    }
    .counter-container {
      width: 315px;
      // gap: 10px;
      padding: 12px 16px;
      border-radius: 5px;
      .counter-num {
        font-weight: 500;
        font-size: 28px;
        width: 35px;
        height: 33px;
        border-radius: 3px;
        margin-right: 10px;
        &:last-child{margin-right: 0;}
      }
      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}
</style>
