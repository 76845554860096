import { v4 as uuidv4 } from 'uuid'
import { getCookie, setCookie } from '@/utils/cookie'

const uuidKey = 'manycaicuuid'

export function getUuid () {
  const uuid = getCookie(uuidKey)
  if (uuid) {
    return uuid
  } else {
    const newUuid = setUuid()
    return newUuid
  }
}

export function setUuid () {
  const uuid = uuidv4()
  setCookie(uuidKey, uuid)
  return uuid
}
