<template>
  <div
    class="sport-news-carousel"
    :class="`${type}-news-carousel`">
    <el-carousel 
      :height="carouselHeight" 
      direction="vertical"
      :interval="4000"
      trigger="click"
      :indicator-position="isMobile ? 'none' : ''"
      arrow="nerver">
      <el-carousel-item 
        v-for="(item, idx) in results" 
        :key="idx">
        <div class="result-container">
          <div class="team-section home-team">
            <div class="team-info">
              <h3>主队</h3>
              <h3 class="team-name">{{item.home_team}}</h3>
            </div>
            <div
              class="team-logo">
              <img :src="defaultImage" v-real-img="item.home_team_logo" />
            </div>
          </div>
          <div class="info-section">
            <h3 class="league">{{item.league}}</h3>
            <div class="info-detail">
              <div class="court">全場</div>
              <div
                class="scores"
                :class="`${type}-scores`">
                <span class="home-score">{{item.home_team_score}}</span>
                <span class="symbol">:</span>
                <span class="away-score">{{item.away_team_score}}</span>
              </div>
              <div class="date">{{item.play_at}}</div>
            </div>
          </div>
          <div class="team-section away-team">
            <div class="team-info">
              <h3>客队</h3>
              <h3 class="team-name">{{item.away_team}}</h3>
            </div>
            <div class="team-logo">
              <img :src="defaultImage" v-real-img="item.away_team_logo" />
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { isMobile } from '@/utils/device'

export default {
  name: 'sportsCarousel',
  props: {
    results: {
      default: null
    },
    type: {
      default: ''
    }
  },
  computed: {
    isMobile () {
      return isMobile()
    },
    carouselHeight () {
      let screenWidth = window.innerWidth
      return  screenWidth < 576 ? '110px' :
              screenWidth < 768 && screenWidth >= 576 ? '196px' :
              screenWidth < 992 && screenWidth >= 768 ? '232px' : '308px'
    },
    defaultImage () {
      return require(`@/assets/${this.type}-default.jpg`)
    }
  }
}
</script>

<style lang="scss">
.sport-news-carousel {
  .el-carousel__indicator--vertical {
    .el-carousel__button {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
      opacity: 1;
    }
  }
  &.football-news-carousel {
    .el-carousel__indicator--vertical {
      &.is-active {
        .el-carousel__button {
          background: #664CBD;
        }
      }
    }
  }
  &.baseketball-news-carousel {
    .el-carousel__indicator--vertical {
      &.is-active {
        .el-carousel__button {
          background: #D38300;
        }
      }
    }
  }
  .result-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .team-section {
      width: 376px;
      height: 100%;
      border: 1px solid #D7D7D7;
      &.home-team {border-radius: 18px 0px 0px 18px;}
      &.away-team {border-radius: 0 18px 18px 0;}
      .team-info {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        h3 {
          display: inline-block;
          font: normal normal normal 25px/38px Poppins;
          color: #000;
        }
        .team-name {
          display: inline-block;
          font: normal normal normal 25px/38px Poppins;
          color: #FFF;
          margin-left: 16px;
        }
      }
      .team-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 252px;
        height: 252px;
        background: #FFF;
        border-radius: 50%;
        margin: 5px auto;
        text-align: center;
        & img {
          width: auto;
          height: auto;
          min-width: 160px;
          max-width: 160px;
        }
      }
    }
    .info-section {
      display: flex;
      width: 318px;
      height: 100%;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #D7D7D7;
      .league {
        width: 100%;
        height: 69px;
        padding: 12px 0;
        font: normal normal bold 30px/46px Poppins;
        color: #FFF;
      }
      .info-detail {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        align-items: center;
        padding: 18px 0 34px;
        .court {
          font: normal normal normal 24px/35px Poppins;
          color: #2F84ED;
        }
        .scores {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #E22626;
          & > span {flex: 1;}
          .home-score {text-align: right;}
          .symbol {
            flex: 0;
            position: relative;
            display: inline-block;
          }
          .away-score {text-align: left;}
        }
        .football-scores {
          font: normal normal bold 105px/120px Arial;
          .symbol {
            font: normal normal bold 105px/126px Times;
            margin: 0 30px;
            bottom: 12px;
          }
        }
        .baseketball-scores {
          font: normal normal bold 60px/69px Arial;
          .symbol{
            font: normal normal bold 60px/72px Times;
            margin: 0 13px;
            bottom: 5px;
          }
        }
        .date {
          font: normal normal normal 18px/27px Poppins;
          color: #5D5D5D;
        }
      }
      
    }
  }
  &.football-news-carousel {
    .result-container {
      .team-section {
        &.home-team {
          background: linear-gradient(0, #C6E5FF 0%, #3D9DEF 100%);
        }
        &.away-team {
          background: linear-gradient(0, #C6B5FF 0%, #5B40B5 100%);
        }
      }
      .info-section {
        .league {background: #001D6C;}
      }
    }
  }
  &.baseketball-news-carousel {
    .result-container {
      .team-section {
        &.home-team {
          background: linear-gradient(0, #BDE585 0%, #599800 100%);
        }
        &.away-team {
          background: linear-gradient(0, #FDD85D 0%, #DEAA00 100%);
        }
      }
      .info-section {
        .league {background: #000000;}
      }
    }
  }
}
@media (max-width: 575.98px) {
  .sport-news-carousel {
    .result-container {
      .team-section {
        width: 30%;
        padding: 5px 0;
        &.home-team {
          border-radius: 0;
        }
        &.away-team {
          border-radius: 0;
        }
        .team-info {
          h3 {
            font: normal normal normal 12px/18px Poppins;
          }
          .team-name {
            font: normal normal normal 12px/18px Poppins;
            margin-left: 8px;
          }
        }
        .team-logo {
          width: 66px;
          height: 66px;
          margin: 8px auto 5px;
          & img {
            width: auto;
            height: auto;
            min-width: 42px;
            max-width: 42px;
          }
        }
      }
      .info-section {
        width: 40%;
        .league {
          height: 28px;
          padding: 0;
          font: normal normal 600 14px/28px Poppins;
        }
        .info-detail {
          padding: 0;
          .court {
            font: normal normal normal 12px/18px Poppins;
          }
          .scores {
            margin-bottom: 3px;
            & > span {flex: 1;}
            .home-score {text-align: right;}
            .symbol {
              flex: 0;
              position: relative;
              display: inline-block;
            }
            .away-score {text-align: left;}
          }
          .football-scores {
            font: normal normal bold 27px/31px Arial;
            .symbol {
              font: normal normal bold 27px/32px Times;
              margin: 0 8px;
              bottom: 2px;
            }
          }
          .baseketball-scores {
            font: normal normal bold 24px/31px Arial;
            .symbol{
              font: normal normal bold 24px/31px Times;
              margin: 0 6px;
              bottom: 2px;
            }
          }
          .date {
            word-break: keep-all;
            font: normal normal normal 12px/14px Poppins;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .sport-news-carousel {
    .result-container {
      .team-section {
        width: 30%;
        padding: 5px 0;
        &.home-team {
          border-radius: 0;
        }
        &.away-team {
          border-radius: 0;
        }
        .team-info {
          h3 {
            font: normal normal normal 15px/20px Poppins;
          }
          .team-name {
            font: normal normal normal 15px/20px Poppins;
            margin-left: 8px;
          }
        }
        .team-logo {
          width: 140px;
          height: 140px;
          margin: 15px auto 5px;
          overflow: hidden;
          & img {
            width: auto;
            height: auto;
            min-width: 90px;
            max-width: 90px;
          }
        }
      }
      .info-section {
        width: 40%;
        .league {
          height: 32px;
          padding: 0;
          font: normal normal 600 18px/32px Poppins;
        }
        .info-detail {
          padding: 5px 0;
          .court {
            font: normal normal normal 15px/20px Poppins;
          }
          .scores {
            margin-bottom: 0;
            & > span {flex: 1;}
            .home-score {text-align: right;}
            .symbol {
              flex: 0;
              position: relative;
              display: inline-block;
            }
            .away-score {text-align: left;}
          }
          .football-scores {
            font: normal normal bold 76px/100px Arial;
            .symbol {
              font: normal normal bold 76px/100px Times;
              margin: 0 10px;
              bottom: 6px;
            }
          }
          .baseketball-scores {
            font: normal normal bold 48px/100px Arial;
            .symbol{
              font: normal normal bold 48px/100px Times;
              margin: 0 6px;
              bottom: 6px;
            }
          }
          .date {
            word-break: keep-all;
            font: normal normal normal 16px/20px Poppins;
            font-size: clamp(14px, 12px, 16px);
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .sport-news-carousel {
    .result-container {
      .team-section {
        .team-info {
          h3 {
            font: normal normal normal 18px/32px Poppins;
          }
          .team-name {
            font: normal normal normal 18px/32px Poppins;
            margin-left: 8px;
          }
        }
        .team-logo {
          width: 164px;
          height: 164px;
          margin: 10px auto 5px;
          overflow: hidden;
          & img {
            width: auto;
            height: auto;
            min-width: 118px;
            max-width: 118px;
          }
        }
      }
      .info-section {
        width: calc(100% - 300px);
        .league {
          height: 42px;
          padding: 0;
          font: normal normal 600 21px/42px Poppins;
        }
        .info-detail {
          padding: 10px 0 10px;
          .court {
            font: normal normal normal 18px/20px Poppins;
          }
          .scores {
            margin-bottom: 3px;
            & > span {flex: 1;}
            .home-score {text-align: right;}
            .symbol {
              flex: 0;
              position: relative;
              display: inline-block;
            }
            .away-score {text-align: left;}
          }
          .football-scores {
            font: normal normal bold 76px/100px Arial;
            .symbol {
              font: normal normal bold 76px/100px Times;
              margin: 0 10px;
              bottom: 6px;
            }
          }
          .baseketball-scores {
            font: normal normal bold 50px/100px Arial;
            .symbol{
              font: normal normal bold 50px/100px Times;
              margin: 0 6px;
              bottom: 6px;
            }
          }
          .date {
            word-break: keep-all;
            font: normal normal normal 18px/20px Poppins;
            font-size: clamp(15px, 13px, 18px);
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  
}
@media (min-width: 1200px) {
  
}
</style>
