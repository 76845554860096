<template>
  <router-link
    :to="handleRouterTo()" >
    历史开奖
  </router-link>
</template>

<script>
import { getLotTabById } from '@/utils/lot'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    handleRouterTo () {
      return {
        name: 'LotteryResult',
        query: {
          tab: getLotTabById(this.id).id,
          id: this.id
        }
      }
    }
  }
}
</script>
