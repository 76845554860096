<template>
  <div class="lot-tool-container">
    <h2>彩种工具</h2>
    <img src="@/assets/home-seven-cai-logo.png" />
    <p>「七彩挂机软件」是专为您经营的平台定制的智能投注挂机软件。该桌面挂机客户端有以下基础功能：模拟投注、外接计划、开某投某、冷热号码、随机出号、自动倍投、推波计算、批量编辑。另外还有高级功能：自动生成挂机方案、逻辑倍投方案设置、方案推送等。</p>
    <div class="btn-container">
      <button @click="handleDownload">通用版下载</button>
      <button @click="handleLearnMore">立即了解</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lotTool',
  methods: {
    handleDownload () {
      window.open('http://manycailm.club/files/QicaiBet_1_0_8_release.exe')
    },
    handleLearnMore () {
      window.open('http://manycailm.club/welfare?pageTab=7cai')
    }
  }
}
</script>

<style lang="scss" scoped>
.lot-tool-container {
  display: flex;
  width: 386px;
  min-height: 100%;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  h2 {
    color: #000000;
    text-align: left;
    align-self: flex-start;
  }
  img {
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    color: #4F4F4F;
    text-align: justify;
    line-height: 23px;
    margin-bottom: 25px;
  }
  .btn-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    button {
      border: none;
      box-sizing: border-box;
      text-align: center;
    }
    :first-child {
      color: #FFFFFF;
      background: linear-gradient(77.4deg, #0053FF 3.38%, #579FFF 91.23%);
    }
    :not(:first-child) {
      color: #2F80ED;
      background: #FFFFFF;
    }
  }
}
@media (max-width: 575.98px) {
  .lot-tool-container {display: none;}
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lot-tool-container {display: none;}
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-tool-container {
    min-width: 240px;
    padding: 20px;
    h2 {
      font-weight: 500;
      font-size: 18px;
    }
    img {
      width: 140.25px;
      height: 151.3px;
      margin-top: 10px;
    }
    .btn-container {
      button {
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        font-weight: 500;
        // font-size: 16px;
        font-size: CLAMP(12px, calc((36vw - 140px)/9), 16px);
        // font-size: MAX(calc((36vw - 150px)/10), 12px);
      }
      :first-child {
        border-radius: 5px;
      }
      :not(:first-child) {
        border: 2px solid #2F84ED;
        border-radius: 5px;
        line-height: 36px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lot-tool-container {
    min-width: 310px;
    padding: 30px 25px;
    h2 {
      font-weight: 500;
      font-size: 21px;
    }
    img {
      width: 165px;
      height: 178px;
    }
    .btn-container {
      button {
        height: 48px;
        padding: 0 18px;
        font-weight: 500;
        // font-size: 18px;
        font-size: CLAMP(14px, calc((36vw - 140px)/9), 18px);
      }
      :first-child {
        border-radius: 5px;
      }
      :not(:first-child) {
        border: 2px solid #2F84ED;
        border-radius: 5px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .lot-tool-container {
    border-radius: 5px;
    padding: 30px 25px;
    h2 {
      font-weight: 500;
      font-size: 21px;
    }
    img {
      width: 165px;
      height: 178px;
      margin-top: -30px;
    }
    p {
      font-size: 14px;
      margin-top: -20px;
    }
    .btn-container {
      button {
        width: fit-content;
        height: 53px;
        padding: 0 30px;
        font-weight: 500;
        font-size: 18px;
      }
      :first-child {
        border-radius: 5px;
      }
      :not(:first-child) {
        border: 2px solid #2F84ED;
        border-radius: 5px;
      }
    }
  }
}
</style>
