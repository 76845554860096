<script>
import demo from './Template/index'

export default {
  ...demo,
  data () {
    return {
      ...demo.data(),
      tab: 'Lot11P5'
    }
  },
  computed: {
    ...demo.computed,
    content: {
      get () {
        return [
          // 广东
          this.tabLots
            .filter(item => item.id === 'GD11X5')[0],
          // 上海
          this.tabLots
            .filter(item => item.id === 'SH11X5')[0],
          // 江苏
          this.tabLots
            .filter(item => item.id === 'JS11X5')[0],
          // 广西
          this.tabLots
            .filter(item => item.id === 'GX11X5')[0]
        ]
      }
    }
  }
}
</script>
