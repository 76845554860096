<script>
import demo from './Template/index'

export default {
  ...demo,
  data () {
    return {
      ...demo.data(),
      tab: 'LotHH'
    }
  },
  computed: {
    ...demo.computed,
    content: {
      get () {
        return [
          // 欢乐生肖
          this.tabLots
            .filter(item => item.id === 'CQSSC')[0],
          // 新疆时时彩
          this.tabLots
            .filter(item => item.id === 'XJSSC')[0],
          // 天津时时彩
          this.tabLots
            .filter(item => item.id === 'TJSSC')[0],
          // 腾讯分分彩
          this.tabLots
            .filter(item => item.id === 'TXFFC')[0]
        ]
      }
    }
  }
}
</script>
