<template>
  <div class="lot-sports">
    <div class="header">
      <h2>体育赛事<span>SPORTS</span></h2>
    </div>
    <div class="sport-container">
      <ul class="sports-tabs">
        <li 
          class="tab"
          v-for="(sport, idx) in sportTabs"
          :key="idx"
          :class="[`tab-${sport.id}`, {'tab-act': sport.id === tab}]"
          @click="changeTab(sport.id)">
          {{sport.context}}
        </li>
      </ul>
      <div 
        class="tab-container"
        :class="`${tab}-container`">
        <div class="subtabs-view">
          <ul class="sports-subtabs">
            <li
              class="subtab"
              v-for="(option, idx) in subTabs"
              :key="idx"
              :class="option.id === subtab ? 'subtab-act': ''"
              @click="changeSubTab(option.id)">
              {{option.context}}
            </li>
          </ul>
        </div>
        <div 
          class="info-container"
          :class="subtab === 'news' ? 'news-info-container' : 'other-info-container'">
          <div>
            <h3>{{containTitle}}</h3>
            <h5 v-if="tab === 'football' && subtab !== 'news'">
              <router-link :to="{ path: '/lotteryInfo' }">
                <i class="el-icon-right"></i>
                {{sportType}}新闻
              </router-link>
            </h5>
            <ul v-if="tab === 'baseketball' && subtab !== 'news'" class="subtype-tabs">
              <li 
                class="tab"
                :class="subtype === type.id ? 'tab-act' : ''"
                v-for="(type, idx) in baseketballSubType"
                v-show="subtab !== 'hhqq' || type.id !== 'dg' "
                :key="type + idx"
                @click="changeSubType(type.id)">
                {{type.context}}
              </li>
            </ul>
          </div>
          <div>
            <div>
              <el-select
                v-if="subtab !== 'news'"
                class="issue-selector"
                v-model="query.issue" 
                size="small"
                :disabled="loading"
                :placeholder="issues[0].label"
                @change="changeIssue">
                <el-option
                  v-for="(issue, idx) in issues"
                  :key="issue.value + idx"
                  :label="issue.label"
                  :value="issue.value">
                </el-option>
              </el-select>
              <button
                v-show="subtab !== 'news'"
                class="issue-button"
                :class="subtab === 'news' ? 'pass-result-button': ''"
                @click="changeCurIssue">
                {{subtab === 'news' ? "过去赛果" : "当期"}}
              </button>
              <button
                class="update-button"
                @click="getResults">
                <i class="el-icon-refresh"></i>
              </button>
            </div>
            <p class="update-time">
              上次更新：{{update_time}}
            </p>
          </div>
        </div>
        <div class="main-container">
          <div 
            v-if="loading"
            class="loading-section">
            <i class="el-icon-loading"></i>
            加載中...
          </div>
          <div 
            v-else
            class="content-section">
            <div v-if="subtab === 'news'">
              <Carousel 
                class="sport-carousel"
                :type="tab"
                :results="result" />
              <router-link :to="{name: 'SportsGames'}">
                <button class="detail-button">
                  <img src="@/assets/game-detail-icon.png">
                  详细赛事
                </button>
              </router-link>
            </div>
            <Table 
              v-else
              :type="subtab"
              :data="result" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sports } from '@/constants/sportsOptions'
import resStatus from '@/constants/resStatus'
import { getDateRange, getZhWeekday } from '@/utils/time'
import moment from 'moment'
import { getFootballInfo, getBaseketballInfo } from '@/api/client/sports'
import Carousel from './Carousel'
import Table from './Table'

export default {
  components: {
    Carousel,
    Table 
  },
  data () {
    return {
      tab: 'football',
      subtab: 'news',
      result: [],
      issues: [{label: '加载中...', value: ''}],
      subtype: 'qg',
      query: {
        type: 'news',
        issue: ''
      },
      update_time: '',
      loading: false
    }
  },
  computed: {
    sportTabs: {
      get() {
        return sports
      }
    },
    subTabs: {
      get() {
        let tabs = this.sportTabs
        let curtab = tabs.find(item => {
          return item.id === this.tab
        })
        return curtab.options
      }
    },
    tabInfo: {
      get() {
        let subtabs = this.subTabs
        return subtabs.find(item => {
          return item.id === this.subtab
        })
      }
    },
    setIssues: {
      get() {
        return this.issues
      },
      set(val) {
        let arr = typeof(val) === 'object' ? val : getDateRange(-7)
        let issues = arr.map((item, idx) => {
          let obj = {}
          obj.label = this.tab === 'football' ? `第${item}期${idx === 0 ? '(当期)' : ''}` : item
          obj.value = item
          return obj
        })
        this.issues = issues
        this.query.issue = this.issues[0].value
        this.loading = false
      }
    },
    containTitle: {
      get() {
        return this.tabInfo.title
      }
    },
    sportType: {
      get() {
        let tabs = this.sportTabs
        let curtab = tabs.find(item => {
          return item.id === this.tab
        })
        return curtab.context
      }
    },
    baseketballSubType: {
      get () {
        let tabs = this.sportTabs
        let curtab = tabs.find(item => {
          return item.id === this.tab
        })
        return curtab.subtype
      }
    },
    transformResultDate: {
      get () {
        return this.result
      },
      set (arr) {
        let resTemp = arr.map(item => {
          let obj = item
          let day = getZhWeekday(item.play_at)
          if (this.subtab === 'news')
            obj.play_at = item.play_at.replaceAll('-', '/').replace(' ', `(周${day}) `)
          else
            obj.play_at = item.play_at.replaceAll('-', '/').replace(' ', `(周${day})<br>`)

          return obj
        })
        this.result = resTemp
      }
    }
  },
  methods: {
    changeTab (id) {
      this.tab = id
      this.issues = [{label: '加载中...', value: ''}]
      this.changeSubTab('news')
    },
    changeSubTab (id) {
      this.subtab = id
      this.query.type = id
      this.query.issue = this.issues[0].value
      this.changeSubType('qg')
      this.getResults()
    },
    changeSubType (id) {
      this.subtype = id
      this.query.subtype = id
      this.getResults()
    },
    changeIssue (cur) {
      if(cur)
        this.query.issue = cur
      this.getResults()
    },
    changeCurIssue () {
      if (this.subtab === 'news')
        return
      let newest = this.issues[0].value
      this.changeIssue(newest)
    },
    resetData () {
      this.result = []
    },
    getResults () {
      this.loading = true
      this.resetData()
      let apiFunc = null, query = this.query
      if (this.tab === 'football') {
        apiFunc = getFootballInfo
      } else {
        apiFunc = getBaseketballInfo
        query.subtype = this.subtype
        if (this.issues.length <= 1)
          this.setIssues = 'today'
      }
      apiFunc(query)
        .then(res => {
          this.loading = false
          if (res.status === resStatus.OK) {
            this.transformResultDate = res.data
            this.update_time = moment().format('YYYY/MM/DD HH:mm:ss');
            if(res.issues && this.issues.length <= 1) 
              this.setIssues = res.issues
          }
        }).catch (err => {
          this.loading = false
          console.log(err)
        })
    }
  },
  created () {
    this.getResults()
  }
}
</script>

<style lang="scss">
button {
  &:focus, &:active {
    outline: none;
  }
}
.issue-selector {
  .el-input__inner {font: normal normal normal 14px/16px Arial;}
}
@media (max-width: 414.98px) {
  .issue-selector {
    .el-input__inner {
      padding-left: 15px;
      font: normal normal normal 12px/16px Arial;
    }
  }
}

</style>
<style lang="scss" scoped>
.lot-sports {
  background: #FFF;
  box-shadow: 0px 12px 10px #0000000D;
  border-radius: 5px 5px 0px 0px;
  padding: 20px 35px 40px;
  cursor: default;
  .header {
    text-align: left;
    margin-bottom: 30px;
    h2 {
      font: normal normal 600 36px/55px Poppins;
      color: #2F84ED;
      span {
        font: normal normal normal 30px/46px Poppins;
        text-transform: uppercase;
        padding-left: 5px;
      }
    }
  }
  .sport-container {
    .sports-tabs {
      text-align: left;
      li {
        display: inline-block;
        position: relative;
        width: 180px;
        height: 50px;
        color: #FFFFFF;
        font: normal normal 600 21px/31px Poppins;
        text-align: left;
        line-height: 50px;
        padding-left: 105px;
        margin: 0 0 0 12px;
        background: #D7D7D7 0% 0% no-repeat padding-box;
        border: 1px solid #D7D7D7;
        border-radius: 15px 15px 0px 0px;
        cursor: pointer;
        &.tab-act {
          &.tab-football {
            background: transparent linear-gradient(180deg, #479550 0%, #11711A 100%) 0% 0% no-repeat padding-box;
            border: 1px solid #15741E;
            &::before {background-image: url('~@/assets/football-act.png');}
          }
          &.tab-baseketball {
            background: transparent linear-gradient(180deg, #EF9842 0%, #E87200 100%) 0% 0% no-repeat padding-box;
            border: 1px solid #D36F0D;
            &::before {background-image: url('~@/assets/baseketball-act.png');}
          }
        }
        &.tab-football::before, &.tab-baseketball::before {
          content: '';
          display: block;
          position: absolute;
          width: 55px;
          height: 55px;
          background-position: center;
          background-size: cover;
          bottom: 5px;
          left: 35px;
        }
        &.tab-football::before {background-image: url('~@/assets/football.png');}
        &.tab-baseketball::before {background-image: url('~@/assets/baseketball.png');}
      }
    }
    .tab-container {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      &.football-container {
        .sports-subtabs {
          background: #269633;
          .subtab-act{color: #269633;}
        }
        .info-container {
          h5 {
            a, i{color: #269633;}
            color: #269633;
            padding-left: 2px;
            &:hover {padding-left: 5px;}
          }
        }
      }
      &.baseketball-container {
        .sports-subtabs {
          background: #EA8119;
          .subtab-act{color: #EA8119;}
        }
        .info-container {
          h5 {color: #EA8119;}
        }
      }
      .sports-subtabs {
        display: flex;
        height: 65px;
        padding: 13px 30px 12px;
        border-radius: 5px 5px 0 0;
        .subtab {
          width: 130px;
          height: 40px;
          padding: 5px 0;
          margin-right: 12px;
          text-align: center;
          font: normal normal normal 18px/27px Poppins;
          color: #FFFFFF;
          border: 1px solid #FFFFFF;
          border-radius: 50px;
          cursor: pointer;
          &.subtab-act {background: #FFF;}
        }
      }
      .info-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: calc(100% - 65px);
        border-right: 1px solid #D7D7D7;
        border-left: 1px solid #D7D7D7;
        padding: 12px 30px 7px;
        & > div {
          &:first-child {
            text-align: left;
            align-self: flex-start;
          }
          &:last-child {
            text-align: right;
          }
        }
        h3 {
          display: inline-block;
          font: normal normal 500 21px/31px Poppins;
          color: #5D5D5D;
        }
        .issue-selector {
          vertical-align: middle;
          input {font: normal normal normal 14px/16px Arial;}
        }
        .issue-button, .update-button {
          height: 30px;
          color: #FFF;
          font: normal normal normal 14px/21px Poppins;
          text-align: center;
          background: #828282 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          vertical-align: middle;
        }
        .issue-button {
          margin: 0 12px;
          padding: 5px 20px;
          &:focus, &:active{
            outline: none;
          }
        }
        .pass-result-button{
          cursor: default;
        }
        .update-button {
          width: 30px;
          & i{
            font-size: 24px;
            line-height: 30px;
          }
        }
        h5 {
          font: normal normal normal 14px/21px Poppins;
          margin-top: 10px;
        }
        .subtype-tabs{
          display: flex;
          align-items: center;
          margin: 20px 0 25px;
          cursor: pointer;
          .tab {
            width: 110px;
            height: 40px;
            font: normal normal normal 18px/40px Poppins;
            color: #FFF;
            text-align: center;
            background: #CEBFBA;
            border-radius: 50px;
            margin-right: 12px;
          }
          .tab-act {
            background: transparent linear-gradient(180deg, #BE532D 0%, #AA4A28 100%) 0% 0% no-repeat padding-box;
          }
        }
        .update-time {
          font: normal normal normal 12px/18px Poppins;
          color: #828282;
          margin-top: 12px;
        }
      }
      .main-container {
        border: 1px solid #D7D7D7;
        border-top: none;
        border-radius: 0 0 5px 5px;
        padding: 0 30px 30px;
        .loading-section {
          font: normal normal normal 16px/24px Poppins;
          color: #828282;
          padding: 50px 0;
        }
        .content-section {
          & > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            & > div {width: 100%;}
            .sport-carousel {margin-bottom: 20px;}
            .detail-button {
              align-self: flex-end;
              width: 200px;
              height: 50px;
              color: #FFFFFF;
              font: normal normal 600 21px/31px Poppins;
              font-weight: 600;
              background: transparent linear-gradient(90deg, #0254FF 0%, #579FFF 100%) 0% 0% no-repeat padding-box;
              border-radius: 5px;
              border: none;
              // margin-top: 20px;
              img {
                width: 73px;
                height: 32px;
              }
              a {color: #FFFFFF;}
            }
          }
        }
      }
    }
  }
}
@media (max-width: 414.98px) {
  .lot-sports {
    border-radius: 0;
    padding: 6px 15px 17px;
    .header {
      margin-bottom: 10px;
      h2 {
        font: normal normal 600 18px/27px Poppins;
        span {
          font: normal normal normal 14px/21px Poppins;
          padding-left: 8px;
        }
      }
    }
    .sport-container {
      .sports-tabs {
        li {
          width: 137px;
          height: 39px;
          font: normal normal 600 16px/25px Poppins;
          line-height: 39px;
          padding-left: 79px;
          margin: 0 0 0 8px;
          &.tab-football::before, &.tab-baseketball::before {
            width: 42px;
            height: 42px;
            bottom: 2px;
            left: 29px;
          }
        }
      }
      .tab-container {
        position: relative;
        border-radius: 0 0 10px 10px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 32px;
          height: 38px;
          top: 0;
          right: 0;
        }
        &.football-container {
          &::before {
            background: transparent linear-gradient(90deg, #26963300 0%, #269633 67%, #269633 100%) 0% 0% no-repeat padding-box;
          }
        }
        &.baseketball-container {
          &::before {
            background: transparent linear-gradient(90deg, #26963300 0%, #EA8119 67%, #EA8119 100%) 0% 0% no-repeat padding-box;
          }
        }
        .subtabs-view {
          width: 100%;
          height: auto;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .sports-subtabs {
          display: block;
          width: max-content;
          height: 100%;
          padding: 7px 11px;
          border-radius: 0;
          .subtab {
            display: inline-block;
            width: 78px;
            height: 28px;
            padding: 0;
            margin-right: 7px;
            font: normal normal normal 12px/28px Poppins;
            &:last-child {margin-right: 14px;}
          }
        }
        .info-container {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          height: auto;
          padding: 12px 10px 4px;
          & > div {
            &:first-child {margin: 0 8px 0 0;}
            &:last-child {
              text-align: left;
            }
          }
          h3 {
            font: normal normal 500 14px/21px Poppins;
            padding-bottom: 5px;
          }
          .issue-selector {
            width: 155px;
            height: 28px;
            margin-right: 8px;
          }
          .issue-button, .update-button {
            height: 28px;
            margin-top: 4px;
          }
          .issue-button {
            margin: 4px 8px 0 0;
            padding: 4px 12px;
            font: normal normal normal 12px/18px Poppins;
          }
          .update-button {
            width: 28px;
            & i{
              font-size: 21px;
              line-height: 28px;
            }
          }
          h5 {
            font: normal normal normal 12px/16px Poppins;
            margin-top: 0;
            margin-bottom: 5px;
          }
          .subtype-tabs{
            margin: 0 0 8px;
            .tab {
              width: 68px;
              height: 28px;
              font: normal normal normal 12px/27px Poppins;
              margin-right: 7px;
            }
          }
          .update-time {
            font: normal normal normal 12px/18px Poppins;
            margin-top: 7px;
          }
          &.news-info-container {
            position: relative;
            flex-direction: column;
            justify-content: space-between;
            h3 {font: normal normal normal 14px/16px Poppins;}
            & > div {
              &:last-child {
                text-align: left;
                & > div {
                  position: absolute;
                  top: 11px;
                  right: 8px;
                }
              }
            }
            .update-button{margin-top: 0;}
          }
          &.other-info-container {
            & > div {
              &:first-child {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              &:last-child {
                width: 100%;
                text-align: left;
              }
            }
          }
        }
        .main-container {
          border-radius: 0 0 10px 10px;
          padding: 0 0 10px;
          .loading-section {
            font: normal normal normal 12px/18px Poppins;
            padding: 30px 0;
          }
          .content-section {
            & > div {
              .sport-carousel {margin-bottom: 10px;}
              .detail-button {
                width: 110px;
                height: 28px;
                font: normal normal 600 11px/28px Poppins;
                // margin-top: 10px;
                align-self: center;
                img {
                  width: 40px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 415px) and (max-width: 575.98px) {
  .lot-sports {
    border-radius: 0;
    padding: 6px 15px 17px;
    .header {
      margin-bottom: 10px;
      h2 {
        font: normal normal 600 18px/27px Poppins;
        span {
          font: normal normal normal 14px/21px Poppins;
          padding-left: 8px;
        }
      }
    }
    .sport-container {
      .sports-tabs {
        li {
          width: 137px;
          height: 39px;
          font: normal normal 600 16px/25px Poppins;
          line-height: 39px;
          padding-left: 79px;
          margin: 0 0 0 8px;
          &.tab-football::before, &.tab-baseketball::before {
            width: 42px;
            height: 42px;
            bottom: 2px;
            left: 29px;
          }
        }
      }
      .tab-container {
        position: relative;
        border-radius: 0 0 10px 10px;
        &::before {
          display: none;
        }
        &.football-container {
          &::before {
            background: transparent linear-gradient(90deg, #26963300 0%, #269633 67%, #269633 100%) 0% 0% no-repeat padding-box;
          }
        }
        &.baseketball-container {
          &::before {
            background: transparent linear-gradient(90deg, #26963300 0%, #EA8119 67%, #EA8119 100%) 0% 0% no-repeat padding-box;
          }
        }
        .subtabs-view {
          width: 100%;
          height: auto;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .sports-subtabs {
          display: block;
          width: 100%;
          height: 100%;
          padding: 7px 11px;
          border-radius: 0;
          text-align: left;
          .subtab {
            display: inline-block;
            width: 78px;
            height: 28px;
            padding: 0;
            margin-right: 7px;
            font: normal normal normal 12px/28px Poppins;
            &:last-child {margin-right: 14px;}
          }
        }
        .info-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          height: auto;
          padding: 12px 8px 4px;
          & > div {
            &:first-child {margin: 0 8px 0 0;}
            &:last-child {
              align-items: flex-end;
              text-align: right;
            }
          }
          h3 {font: normal normal normal 14px/21px Poppins;}
          .issue-selector {
            width: 155px;
            height: 28px;
            margin-right: 8px;
          }
          .issue-button, .update-button {
            height: 28px;
            margin-top: 4px;
          }
          .issue-button {
            margin: 4px 8px 0 0;
            padding: 4px 12px;
          }
          .update-button {
            width: 28px;
            & i{
              font-size: 22px;
              line-height: 28px;
            }
          }
          h5 {
            margin-top: 2px;
            margin-bottom: 5px;
          }
          .subtype-tabs{
            margin: 5px 0 8px;
            .tab {
              width: 68px;
              height: 30px;
              font: normal normal normal 16px/29px Poppins;
              margin-right: 7px;
            }
          }
          .update-time {
            font: normal normal normal 10px/18px Poppins;
            margin-top: 6px;
          }
        }
        .main-container {
          border-radius: 0 0 10px 10px;
          padding: 0 0 10px;
          .loading-section {
            font: normal normal normal 12px/18px Poppins;
            padding: 30px 0;
          }
          .content-section {
            & > div {
              .sport-carousel {margin-bottom: 10px;}
              .detail-button {
                width: 110px;
                height: 28px;
                font: normal normal 600 11px/28px Poppins;
                // margin-top: 10px;
                align-self: center;
                img {
                  width: 40px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lot-sports {
    border-radius: 0;
    padding: 12px 15px 17px;
    .header {
      margin-bottom: 12px;
      h2 {
        font: normal normal 600 18px/27px Poppins;
        span {
          font: normal normal normal 14px/21px Poppins;
          padding-left: 8px;
        }
      }
    }
    .sport-container {
      .sports-tabs {
        li {
          width: 137px;
          height: 39px;
          font: normal normal 600 16px/25px Poppins;
          line-height: 39px;
          padding-left: 79px;
          margin: 0 0 0 8px;
          &.tab-football::before, &.tab-baseketball::before {
            width: 42px;
            height: 42px;
            bottom: 2px;
            left: 29px;
          }
        }
      }
      .tab-container {
        position: relative;
        border-radius: 0 0 10px 10px;
        &::before {
          display: none;
        }
        &.football-container {
          &::before {
            background: transparent linear-gradient(90deg, #26963300 0%, #269633 67%, #269633 100%) 0% 0% no-repeat padding-box;
          }
        }
        &.baseketball-container {
          &::before {
            background: transparent linear-gradient(90deg, #26963300 0%, #EA8119 67%, #EA8119 100%) 0% 0% no-repeat padding-box;
          }
        }
        .subtabs-view {
          width: 100%;
          height: auto;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .sports-subtabs {
          display: block;
          width: 100%;
          height: 100%;
          text-align: left;
          padding: 7px 11px;
          border-radius: 0;
          .subtab {
            display: inline-block;
            width: 78px;
            height: 30px;
            margin-right: 10px;
            font: normal normal normal 12px/29px Poppins;
            padding: 0;
            &:last-child {margin-right: 14px;}
          }
        }
        .info-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          height: auto;
          padding: 12px 15px 5px;
          & > div {
            &:first-child {margin: 0 8px 0 0;}
            &:last-child {
              text-align: left;
            }
          }
          .issue-selector {
            width: 155px;
            height: 28px;
            margin-right: 8px;
          }
          .issue-button, .update-button {
            height: 28px;
            margin-top: 4px;
          }
          .issue-button {
            margin: 4px 8px 0 0;
            padding: 4px 12px;
          }
          .update-button {
            width: 28px;
            & i{
              font-size: 22px;
              line-height: 28px;
            }
          }
          h5 {
            margin-top: 2px;
            margin-bottom: 5px;
          }
          .subtype-tabs{
            margin: 5px 0 8px;
            .tab {
              width: 68px;
              height: 30px;
              font: normal normal normal 16px/29px Poppins;
              margin-right: 7px;
            }
          }
          .update-time {
            font: normal normal normal 10px/18px Poppins;
            margin-top: 6px;
          }
          &.news-info-container {
            flex-direction: row;
            justify-content: space-between;
            padding: 10px 15px 5px;
            & > div {
              &:last-child {
                text-align: right;
              }
            }
          }
        }
        .main-container {
          border-radius: 0 0 10px 10px;
          padding: 0 0 10px;
          .loading-section {
            font: normal normal normal 12px/18px Poppins;
            padding: 30px 0;
          }
          .content-section {
            & > div {
              .sport-carousel {margin-bottom: 10px;}
              .detail-button {
                width: 110px;
                height: 28px;
                font: normal normal 600 11px/28px Poppins;
                // margin-top: 10px;
                align-self: center;
                img {
                  width: 40px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-sports {
    border-radius: 0;
    padding: 12px 15px 17px;
    .header {
      margin-bottom: 12px;
      h2 {
        font: normal normal 600 18px/27px Poppins;
        span {
          font: normal normal normal 14px/21px Poppins;
          padding-left: 8px;
        }
      }
    }
    .sport-container {
      .sports-tabs {
        li {
          width: 137px;
          height: 39px;
          font: normal normal 600 16px/25px Poppins;
          line-height: 39px;
          padding-left: 79px;
          margin: 0 0 0 8px;
          &.tab-football::before, &.tab-baseketball::before {
            width: 42px;
            height: 42px;
            bottom: 2px;
            left: 29px;
          }
        }
      }
      .tab-container {
        position: relative;
        border-radius: 0 0 10px 10px;
        &::before {
          display: none;
        }
        &.football-container {
          &::before {
            background: transparent linear-gradient(90deg, #26963300 0%, #269633 67%, #269633 100%) 0% 0% no-repeat padding-box;
          }
        }
        &.baseketball-container {
          &::before {
            background: transparent linear-gradient(90deg, #26963300 0%, #EA8119 67%, #EA8119 100%) 0% 0% no-repeat padding-box;
          }
        }
        .subtabs-view {
          width: 100%;
          height: auto;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .sports-subtabs {
          display: block;
          width: 100%;
          height: 100%;
          text-align: left;
          padding: 7px 11px;
          border-radius: 0;
          .subtab {
            display: inline-block;
            width: 78px;
            height: 30px;
            margin-right: 10px;
            font: normal normal normal 12px/29px Poppins;
            padding: 0;
            &:last-child {margin-right: 14px;}
          }
        }
        .info-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          height: auto;
          padding: 12px 15px 5px;
          & > div {
            &:first-child {margin: 0 8px 0 0;}
            &:last-child {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-self: stretch;
              text-align: right;
            }
          }
          .issue-selector {
            width: 155px;
            height: 28px;
            margin-right: 8px;
          }
          .issue-button, .update-button {
            height: 28px;
            margin-top: 4px;
          }
          .issue-button {
            margin: 4px 8px 0 0;
            padding: 4px 12px;
          }
          .update-button {
            width: 28px;
            & i{
              font-size: 22px;
              line-height: 28px;
            }
          }
          h5 {
            margin-top: 2px;
            margin-bottom: 5px;
          }
          .subtype-tabs{
            margin: 5px 0 8px;
            .tab {
              width: 68px;
              height: 30px;
              font: normal normal normal 16px/29px Poppins;
              margin-right: 7px;
            }
          }
          .update-time {
            font: normal normal normal 10px/18px Poppins;
            margin-top: 6px;
          }
          &.news-info-container {
            flex-direction: row;
            justify-content: space-between;
            padding: 10px 15px 5px;
            & > div {
              &:last-child {
                text-align: right;
              }
            }
          }
        }
        .main-container {
          border-radius: 0 0 10px 10px;
          padding: 0 0 10px;
          .loading-section {
            font: normal normal normal 12px/18px Poppins;
            padding: 30px 0;
          }
          .content-section {
            padding: 0 10px;
            & > div {
              .sport-carousel {margin-bottom: 10px;}
              .detail-button {
                width: 110px;
                height: 28px;
                font: normal normal 600 11px/28px Poppins;
                // margin-top: 10px;
                align-self: center;
                img {
                  width: 40px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  
}
@media (min-width: 1200px) {
  
}
</style>