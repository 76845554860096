<template>
  <div class="lot-container">
    <lot-thumbnail
      v-for="lot in content"
      :key="lot.id"
      class="lot-thumbnail"
      :lotInfo="lot"
      :componentActive="componentActive" />
  </div>
</template>

<script>
import { allLots } from '@/constants/lots'
import LotThumbnail from '@/components/home/LotThumbnail'

export default {
  components: {
    LotThumbnail
  },
  data () {
    return {
      tab: 'template',
      componentActive: false
    }
  },
  computed: {
    content: {
      get () {
        return this.tabLots
      }
    },
    tabLots: {
      get () {
        return (
          allLots
            .filter(item => item.id === this.tab)[0]
            .children
        )
      }
    }
  },
  activated () {
    this.componentActive = true
  },
  deactivated () {
    this.componentActive = false
  }
}
</script>

<style lang="scss" scoped>
.lot-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  // gap: 15px;
  & > div{margin-bottom: 15px;}
}
@media (max-width: 575.98px) {
  .lot-container {
    // gap: 10px;
    padding: 5px 15px 20px;
    & > div{margin-bottom: 10px;}
  }
  .lot-thumbnail {
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lot-container {
    // gap: 10px;
    padding: 5px 15px 20px;
    & > div{margin-bottom: 10px;}
  }
  .lot-thumbnail {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-thumbnail {
    width: 48%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lot-thumbnail {
    width: 48%;
  }
}
@media (min-width: 1200px) {
  .lot-thumbnail {
    width: 48%;
  }
}
</style>
