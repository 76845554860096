<template>
  <div class="link-container">
    <!-- 历史开奖 -->
    <link-history
      v-if="displayItem.history"
      :id="id" />
    <div
      v-if="displayItem.history"
      class="divide">
    </div>

    <!-- 基本走势 -->
    <link-basic-trend
      v-if="displayItem.trend"
      :id="id" />
    <div
      v-if="displayItem.trend"
      class="divide">
    </div>

    <!-- 定位走势 -->
    <link-dy-trend
      v-if="displayItem.positionTrend"
      :id="id" />
    <div
      v-if="displayItem.positionTrend"
      class="divide">
    </div>

    <!-- 号码预测 -->
    <link-num-analysis
      v-if="displayItem.prediction"
      :id="id" />
    <div
      v-if="displayItem.prediction"
      class="divide">
    </div>

    <!-- 单双大小历史 -->
    <link-ds-ls-history
      v-if="displayItem.LSHistory"
      :id="id" />
    <div
      v-if="displayItem.LSHistory"
      class="divide">
    </div>

    <!-- 冠亚和走势 -->
    <link-champion-trend
      v-if="displayItem.CRTrend"
      :id="id" />
    <div
      v-if="displayItem.CRTrend"
      class="divide">
    </div>
  </div>
</template>

<script>
import LinkHistory from './LinkHistory'
import LinkBasicTrend from './LinkBasicTrend'
import LinkDyTrend from './LinkDyTrend'
import LinkNumAnalysis from './LinkNumAnalysis'
import LinkDsLsHistory from './LinkDsLsHistory'
import LinkChampionTrend from './LinkChampionTrend'

export default {
  components: {
    LinkHistory,
    LinkBasicTrend,
    LinkDyTrend,
    LinkNumAnalysis,
    LinkDsLsHistory,
    LinkChampionTrend
  },
  props: {
    id: {
      type: String,
      required: true
    },
    displayItem: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #D7D7D7;
.link-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
a {
  color: #4F4F4F;
}
@media (max-width: 575.98px) {
  .link-container{display: none;}
  .divide{display: none;}
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .link-container{display: none;}
  .divide{display: none;}
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .link-container {
    .divide:last-child {
      display: none;
    }
  }
  .divide {
    width: 0px;
    height: 21px;
    border-right: 1px solid $border-color;
  }
  a {
    font-size: 13px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .link-container {
    .divide:last-child {
      display: none;
    }
  }
  .divide {
    width: 0px;
    height: 23px;
    border-right: 1px solid $border-color;
  }
  a {
    font-size: 13px;
  }
}
@media (min-width: 1200px) {
  .link-container {
    .divide:last-child {
      display: none;
    }
  }
  .divide {
    width: 0px;
    height: 26px;
    border-right: 1px solid $border-color;
  }
  a {
    font-size: 13px;
  }
}
</style>
