<template>
  <div 
    class="table-view"
    :class="`${sport}-table-view`">
    <div
      v-for="(item, tidx) in tableData"
      :key="item + tidx"
      class="table-section">
      <b-table-simple
        bordered>
        <b-thead
          v-if="tidx === 0">
          <b-tr>
            <b-th 
              v-for="field in fields"
              :key="field.key"
              :class="field.thClass">
              <span v-if="field.key === 'bet'" v-html="field.label"></span>
              <span v-else>{{field.label}}</span>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(row, rowidx) in item"
            :key="rowidx + row">
            <b-td
              v-for="(field, colidx) in fields" 
              v-show="rowidx % 2 == 0 || rowidx % 2 !== 0 && (field.key !== 'league' && field.key !== 'play_at' && field.key !== 'home_team' && field.key !== 'away_team')"
              :class="field.tdClass"
              :rowspan="rowidx % 2 == 0 && (field.key == 'league' || field.key == 'play_at' || field.key == 'home_team' || field.key == 'away_team') ? 2 : 1"
              :key="field.key + colidx">
              <div v-if="field.key === 'play_at'" v-html="row[field.key]"></div>
              <div v-else-if="field.key === 'result'">
                <span :class="`result-color-${row.result}`">{{row[field.key]}}</span>
                <span>
                  {{resultCode.hasOwnProperty(row.result) ? 
                  ` (${resultCode[row.result]})` : ''}}
                </span>
              </div>
              <div v-else-if="field.key === 'bet'" class="flex">
                <div>3</div>
                <div>1</div>
                <div>0</div>
              </div>
              <div 
                v-else
                :class="field.key === 'scores' ? `result-color-${row.result}` : ''">{{ row[field.key]}}</div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'sport'],
  data () {
    return {
      result: null,
      fields: [
        { key: 'serialno', label: '场次', tdClass: 'serialno-col', thClass: 'serialno-col'},
        { key: 'league', label: '赛事', tdClass: 'league-col', thClass: 'league-col',},
        { key: 'play_at', label: '比赛时间', tdClass: 'play_at-col', thClass: 'play_at-col',},
        { key: 'home_team', label: '主场', tdClass: 'team-col', thClass: 'team-col'},
        { key: 'court', label: '半/全', tdClass: 'court-col', thClass: 'court-col'},
        { key: 'scores', label: '比分', tdClass: 'scores-col', thClass: 'scores-col'},
        { key: 'away_team', label: '客场', tdClass: 'team-col', thClass: 'team-col'},
        { key: 'result', label: '彩果(胜负)', tdClass: 'result-col', thClass: 'result-col'},
        { key: 'bet', label: '下注金额试算器<br>(投注试算功能即将更新)', tdClass: 'bet-col', thClass: 'bet-col'}
        // { key: 'bet', label: '下注金额试算器<br>3(胜) 1(平) 0(负)', tdClass: 'bet-col', thClass: 'bet-col'}
      ],
      resultCode: {
        0: '主負',
        1: '平手',
        3: '主胜'
      }
    }
  },
  computed: {
    tableData: {
      get () {
        return this.result
      },
      set (data) {
        let arr = []
        if (data) {
          arr = data.map(item => {
            return this.handleData(item)
          })
        }
        this.result = arr
      }
    }
  },
  created () {
    this.tableData = this.data
  },
  watch: {
    data (cur, prev) {
      if (cur !== prev) 
        this.tableData = this.data
    }
  },
  methods: {
    handleData (obj) {
      let arr = []
      for(let i = 0; i < 2; i++) {
        let serial = this.serialNum(obj.serialno)
        let firstCol = i % 2 === 0
        let courtScore = firstCol ? obj.half : obj.full
        arr.push({
          serialno: serial[i],
          league: obj.league,
          play_at: obj.play_at,
          home_team: obj.home_team,
          away_team: obj.away_team,
          court: firstCol ? '半' : '全',
          scores: courtScore.home_team_score === '*' && courtScore.away_team_score === '*' ?
                  '＊' :
                  `${courtScore.home_team_score || '－'} : ${courtScore.away_team_score || '－'}`,
          result: this.getResultCode(courtScore.result_code)
        })
      }
      return arr
    },
    getResultCode (code) {
      return Number(code) >= 0 ? Number(code) : '－'
    },
    serialNum (str) {
      return str.split('-')
    },
    handleWinLose (code) {
      return code !== '－' ? `(${this.resultCode[code]})` : ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>