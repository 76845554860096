<template>
  <div>
    <b-carousel
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      class="carousel-container"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <b-carousel-slide
        v-for="(ad, idx) in banners"
        :key="idx+ad"
      >
        <template #img>
          <img
            @click="handleImgClick(ad.href, ad.id)"
            class="d-block img-fluid w-100 h-fit mcai-slide-img"
            :src="$common.resSrc(ad.photo)"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <!-- <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100 slide-item"
            width="1024"
            height="480"
            src="https://picsum.photos/1024/480/?image=55"
            alt="image slot"
          >
        </template>
      </b-carousel-slide> -->
    </b-carousel>
  </div>
</template>

<script>

import { goWebsite } from '@/utils/route'
import { getBanners } from '@/api/client/banner'
import resStatus from '@/constants/resStatus'
import { visit } from '@/api/client/visit'

export default {
  name: 'mainCarousel',
  data () {
    return {
      slide: 0,
      sliding: null,
      banners: [],
      visitQuery: {
        to: 'ad',
        uuid: '',
        id: ''
      },
      bannerQuery: {
        position_ids: '2'
      },
      ads: []
    }
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    handleImgClick (link, id) {
      this.sendVisit(id)
      goWebsite(link, '_blank')
    },
    sendVisit (id) {
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    getCommercials () {
      getBanners(this.bannerQuery)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.banners = res.data[2]
          }
        })
    }
  },
  created () {
    this.getCommercials()
  }
}
</script>

<style lang="scss" scoped>
.carousel-container {
  border-radius: 5px 0px 0px 5px;
  img {
    width: 100vw;
    height: 49.26vw;
  }
}
@media (max-width: 575.98px) {
}
@media (min-width: 576px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .carousel-container {
    img {
      width: 670px;
      height: 330px;
    }
  }
}
@media (min-width: 1200px) {
  .carousel-container {
    img {
      width: 812px;
      height: 400px;
    }
  }
}
</style>

<style lang="scss">
.h-fit {
  height: 100%;
}
.mcai-slide-img {
  cursor: pointer;
}
.carousel-inner {
  border-radius: 5px 0px 0px 5px;
}
@media (max-width: 575.98px) {
  .carousel-inner {
    border-radius: 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .carousel-inner {
    border-radius: 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
  .h-fit {
    height: -webkit-fill-available;
  }
}
</style>
