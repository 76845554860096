<template>
  <div v-if="data.length == 0" class="sport-table-no-data">
    尚无资讯
  </div>
  <div v-else class="sport-table-template">
    <Table14sf v-if="type === '14sf'" :data="data" sport="football" />
    <Table6cb v-if="type === '6cb'" :data="data" sport="football" />
    <TableRx9 v-if="type === 'rx9'" :data="data" sport="football" />
    <Table4cjq v-if="type === '4cjq'" :data="data" sport="football" />
    <TableDsf v-if="type === 'dsf'" :data="data" sport="baseketball" />
    <TableHhqq v-if="type === 'hhqq'" :data="data" sport="baseketball" />
    <TableSfc v-if="type === 'sfc'" :data="data" sport="baseketball" />
    <TableSflf v-if="type === 'sflf'" :data="data" sport="baseketball" />
  </div>
</template>

<script>
import Table14sf from './Football/14sf'
import Table6cb from './Football/6cb'
import TableRx9 from './Football/rx9'
import Table4cjq from './Football/4cjq'
import TableDsf from './Baseketball/dsf'
import TableHhqq from './Baseketball/hhqq'
import TableSfc from './Baseketball/sfc'
import TableSflf from './Baseketball/sflf'

export default {
  components: {
    Table14sf,
    Table6cb,
    TableRx9,
    Table4cjq,
    TableDsf,
    TableHhqq,
    TableSfc,
    TableSflf
  },
  props: {
    data: {
      type: Array,
      default: []
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.sport-table-no-data {
  padding: 50px 0;
  color: #828282;
  font: normal normal normal 16px/25px Poppins;
}
.sport-table-template {
  width: 100%;
  min-height: 100px;
}
</style>

<style lang="scss">
.sport-table-template {
  @each $i, $color in (0: #2F84ED, 1:#269633, 3:#EB5757) {
    .result-color-#{$i} {
      color: $color;
      font: normal normal normal 16px/20px Poppins;
    }
  }
  .w-50 {width: 50%;}
  .table-section {
    margin-bottom: 5px;
  }
  .table-view {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {display: none;}
    .table {
      margin-bottom: 0;
      .blue {color: #2F84ED;}
      .red {color: #EB5757;}
      .yellow {color: #FFF39A;}
      .text-right{text-align: right;}
      th {
        font: normal normal normal 16px/25px Poppins!important;
        color: #FFF;
        padding: 18px 5px;
        vertical-align: middle;
        border-bottom: 1px;
        text-align: center;
      }
      td {
        font: normal normal normal 13px/20px Poppins;
        color: #5D5D5D;
        vertical-align: middle;
        border-color: #D7D7D7;
        & .flex {
          display: flex;
          align-items: center;
        }
        & .flex-around {
          justify-content: space-around!important;
        }
      }
      .serialno-col {
        width: 67px;
        min-width: 67px;
      }
      .league-col {
        width: 120px;
        min-width: 100px;
      }
      .playtime-col, .play_at-col {
        width: 155px;
        min-width: 155px;
        max-width: 190px;
      }
      .court-col {
        width: 55px;
        min-width: 55px;
      }
      .team-col {
        // width: 198px;
        width: 198px;
        min-width: 140px;
        & .flex {
          align-items: flex-end;
          & > div {
            &:first-child, &:last-child {
              width: 40%;
              & > div:first-child {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      .scores-col, .score-col, .total_score-col {
        width: 100px;
        font: normal normal normal 16px/25px Poppins;
      }
      .scores-col, .score-col, .bs-col {
        position: relative;
        & .flex {
          justify-content: center;
          span {
            display: inline-block;
            font: normal normal normal 13px/20px Poppins;
          }
          div{
            &:nth-child(2){margin: 5px;}
          }
        }
      }
      .total_score-col{width: 120px;}
      .spread-col {
        width: 100px;
        font: normal normal normal 16px/25px Poppins;
        &.spread-red {color: #EB5757;}
        &.spread-green {color: #269633;}
      }
      .result-col {
        width: 100px;
        min-width: 80px;
        word-break: keep-all;
      }
      .bet-col {
        width: 238px;
        min-width: 180px;
        & .flex {
          justify-content: space-evenly;
        }
      }
    }
  }
  .football-table-view {
    .table {
      th {
        background-color: #5A715C!important;
        color: #FFFFFF!important;
      }
    }
  }
  .baseketball-table-view {
    .table {
      th {
        background-color: #71605A!important;
        color: #FFFFFF;
        padding: 12px 5px
      }
      td {
        padding: 8px 5px;
      }
      .ds-col {
        padding: 5px;
      }
      .score-col, .spread-col{
        font: normal normal normal 16px/25px Poppins;
        .spread-red {color: #EB5757;}
        .spread-green {color: #269633;}
      }
      .bet-rate-col {
        width: 65px;
      }
      .team-col {
        span {
          padding-left: 20px;
          padding-right: 15px;
          color: #EB5757;
        }
      }
      .bet-col[colspan="2"] {padding: 5px;}
    }
    .hhqq-table-section {
      .table {
        .spreadbet-col {width: 100px;}
        .score-col {width: 100px;}
        .team-col {width: 198px;}
        .spread-col {width: 80px;}
        .dsbet-col {width: 165px;}
        .sf-col {width: 80px;}
        .total-col {width: 70px;}
        .ds-col {width: 95px;}
        .sfc-col {
          padding: 0;
          .sfc-table {
            tr {
              &:last-child {
                td {border-bottom: 0px;}
              }
              th {
                width: auto;
                font: normal normal normal 16px/25px Poppins;
                border-left: 0;
                background: #B9A8A2;
                padding: 5px;
                &:first-child {
                  width: 100px;
                  border-top: 1px solid #dee2e6;
                  font: normal normal normal 13px/20px Poppins;
                }
              }
              td{
                &:first-child {border-left: 0;}
                &:last-child {border-right: 0;}
              }
              &:first-child{
                td{
                  &:first-child{border-right: 0;}
                  border-top: 0;
                }
                th {
                  border-top: 0;
                  &:last-child {border-right: 0;}
                }
              }
              .sfc-header {border-top: 1px solid #dee2e6!important;}
            }          
          }
        }
      }
    }
  }
}
@media (max-width: 767.98px) {
  .sport-table-template {
    padding: 0;
    .table-view {
      .table {
        th {
          font: normal normal normal 12px/18px Poppins!important;
          padding: 6px 4px;
        }
        td {
          font: normal normal normal 12px/18px Poppins!important;
          padding: 6px 4px;
          word-break: keep-all;
          &.scores-col, &.score-col, &.total_score-col, &.spread-col {
            font: normal normal normal 14px/20px Poppins!important;
          }
        }
        .serialno-col {
          width: 55px;
          min-width: 55px;
        }
        .league-col {
          width: 120px;
          min-width: 100px;
        }
        .playtime-col, .play_at-col {
          width: 150px;
          min-width: 150px;
          max-width: 180px;
        }
        .court-col {
          width: 50px;
          min-width: 50px;
        }
        .team-col {
          width: 150px;
          min-width: 130px;
          & > .flex {
            align-items: flex-end;
            & > div {
              &:first-child, &:last-child {
                & > div:first-child {
                  margin-bottom: 12px;
                }
              }
            }
          }
        }
        .scores-col, .score-col, .total_score-col{
          width: 90px;
          min-width: 90px;
        }
        .scores-col, .score-col, .bs-col {
          & .flex {
            justify-content: center;
            div{
              &:nth-child(2){margin: 5px;}
            }
          }
        }
        .total_score-col{
          width: 120px;
          min-width: 100px;
        }
        .spread-col {
          width: 100px;
          min-width: 90px;
          &.spread-red {color: #EB5757;}
          &.spread-green {color: #269633;}
        }
        .result-col {
          width: 100px;
          min-width: 80px;
          word-break: keep-all;
        }
        .bet-col {
          width: 180px;
          min-width: 160px;
        }
      }
    }
    .baseketball-table-view {
      .table {
        .ds-col {
          padding: 5px;
        }
        .bet-rate-col {
          width: 65px;
        }
        .team-col {
          span {
            padding-left: 20px;
            padding-right: 15px;
          }
        }
        .bet-col[colspan="2"] {padding: 5px;}
      }
      .hhqq-table-section {
        .table {
          .spreadbet-col {width: 100px; min-width: 120px;}
          .score-col {width: 100px; min-width: 120px;}
          .team-col {width: 180px; min-width: 160px;}
          .spread-col {width: 80px; min-width: 80px;}
          .dsbet-col {width: 165px; min-width: 160px;}
          .sf-col {width: 80px; min-width: 80px;}
          .total-col {width: 70px; min-width: 70px;}
          .ds-col {width: 95px; min-width: 90px;}
          .sfc-col {
            padding: 0;
            .sfc-table {
              tr {
                &:last-child {
                  td {border-bottom: 0px;}
                }
                th {
                  width: 50px;
                  min-width: 50px;
                  &:first-child {
                    width: 82px;
                    min-width: 82px;
                  }
                }
              }          
            }
          }
        }
      }
    }
  }
}
// @media (min-width: 576px) and (max-width: 767.98px) {
  
// }
@media (min-width: 768px) {
  .sport-table-template {
    padding: 0;
    .table-view {
      .table {
        th {
          font: normal normal normal 12px/18px Poppins!important;
          padding: 6px 4px;
        }
        td {
          font: normal normal normal 12px/18px Poppins!important;
          padding: 6px 4px;
          word-break: keep-all;
          &.scores-col, &.score-col, &.total_score-col, &.spread-col {
            font: normal normal normal 14px/20px Poppins!important;
          }
        }
        .serialno-col {
          width: 55px;
          min-width: 55px;
        }
        .league-col {
          width: 120px;
          min-width: 100px;
        }
        .playtime-col, .play_at-col {
          width: 150px;
          min-width: 150px;
          max-width: 180px;
        }
        .court-col {
          width: 50px;
          min-width: 50px;
        }
        .team-col {
          width: 150px;
          min-width: 130px;
          & > .flex {
            align-items: flex-end;
            & > div {
              &:first-child, &:last-child {
                & > div:first-child {
                  margin-bottom: 12px;
                }
              }
            }
          }
        }
        .scores-col, .score-col, .total_score-col{
          width: 90px;
          min-width: 90px;
        }
        .scores-col, .score-col, .bs-col {
          & .flex {
            justify-content: center;
            div{
              &:nth-child(2){margin: 5px;}
            }
          }
        }
        .total_score-col{
          width: 120px;
          min-width: 100px;
        }
        .spread-col {
          width: 100px;
          min-width: 90px;
          &.spread-red {color: #EB5757;}
          &.spread-green {color: #269633;}
        }
        .result-col {
          width: 100px;
          min-width: 80px;
          word-break: keep-all;
        }
        .bet-col {
          width: 180px;
          min-width: 160px;
        }
      }
    }
    .baseketball-table-view {
      .table {
        .ds-col {
          padding: 5px;
        }
        .bet-rate-col {
          width: 65px;
        }
        .team-col {
          span {
            padding-left: 20px;
            padding-right: 15px;
          }
        }
        .bet-col[colspan="2"] {padding: 5px;}
      }
      .hhqq-table-section {
        .table {
          .spreadbet-col {width: 100px; min-width: 120px;}
          .score-col {width: 100px; min-width: 120px;}
          .team-col {width: 180px; min-width: 160px;}
          .spread-col {width: 80px; min-width: 80px;}
          .dsbet-col {width: 165px; min-width: 160px;}
          .sf-col {width: 80px; min-width: 80px;}
          .total-col {width: 70px; min-width: 70px;}
          .ds-col {width: 95px; min-width: 90px;}
          .sfc-col {
            padding: 0;
            .sfc-table {
              tr {
                &:last-child {
                  td {border-bottom: 0px;}
                }
                th {
                  width: 50px;
                  min-width: 50px;
                  &:first-child {
                    width: 82px;
                    min-width: 82px;
                  }
                }
              }          
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  
}
@media (min-width: 1200px) {
  
}
</style>