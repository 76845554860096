<template>
  <div class="home-container">
    <main-view />
    <header-commercial v-if="isMobile" />
    <hot-lot />
    <lot-lobby />
    <sports />
    <commercial />
    <div class="info-tool-container">
      <lot-info
        class="lot-info"
        show='home'
      />
      <lot-tool class="lot-tool" />
    </div>
  </div>
</template>

<script>
import MainView from './MainView'
import HotLot from './HotLot'
import LotLobby from './LotLobby'
import Commercial from './Commercial'
import HeaderCommercial from '@/views/Layout/Header/Main/commercial'
import LotInfo from './LotInfo'
import LotTool from '@/components/LotteryInfo/LotTool'
import Sports from './Sports'
import { isMobile } from '@/utils/device'

export default {
  name: 'Home',
  components: {
    MainView,
    HotLot,
    LotLobby,
    Commercial,
    LotInfo,
    LotTool,
    Sports,
    HeaderCommercial
  },
  computed: {
    isMobile() {
      return isMobile()
    }
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  // width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .info-tool-container {
    display: flex;
    height: fit-content;
    justify-content: space-around;
    align-items: stretch;
    .lot-info {
      width: auto;
      min-height: 100%;
      flex-grow: 99;
    }
  }
}
@media (max-width: 575.98px) {
  .home-container {
    width: 100%;
    padding: 0;
    // gap: 10px;
    box-sizing: border-box;
    & > div {
      margin-bottom: 10px;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .home-container {
    width: 100%;
    padding: 0;
    // gap: 10px;
    box-sizing: border-box;
    & > div {
      margin-bottom: 10px;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .home-container {
    width: 98%;
    padding: 10px 0;
    // gap: 10px;
    box-sizing: border-box;
    & > div {
      margin-bottom: 10px;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
    .info-tool-container {
      display: flex;
      height: fit-content;
      justify-content: space-around;
      align-items: stretch;
      // gap: 20px;
      & > div {
        margin-right: 20px;
      }
      & > div:last-child {
        margin-right: 0;
      }
      .lot-info {
        width: auto;
        min-width: 450px;
        min-height: 100%;
        flex-grow: 99;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .home-container {
    width: 97%;
    padding: 15px 0;
    // gap: 15px;
    box-sizing: border-box;
    & > div {
      margin-bottom: 15px;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
    .info-tool-container {
      height: fit-content;
      display: flex;
      justify-content: space-around;
      align-items: stretch;
      // gap: 20px;
      & > div {
        margin-right: 20px;
      }
      & > div:last-child {
        margin-right: 0;
      }
      .lot-info {
        width: 660px;
        min-width: 660px;
        min-height: 100%;
        flex-grow: 99;
      }
    }
  }
}
@media (min-width: 1200px) {
  .home-container {
    padding: 20px 0;
    // gap: 20px;
    box-sizing: border-box;
    & > div {
      margin-bottom: 20px;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
    .info-tool-container {
      height: fit-content;
      display: flex;
      justify-content: space-around;
      // gap: 20px;
      & > div {
        margin-right: 20px;
      }
      & > div:last-child {
        margin-right: 0;
      }
      .lot-info {
        width: 792px;
        min-width: 792px;
        min-height: 100%;
        flex-grow: 99;
      }
    }
  }
}
</style>
