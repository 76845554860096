/*
  時時彩、分分彩
  import {} from '@/utils/display/MM'
*/
import { commonCodeNums } from '@/utils/display/common'

// 五位數取前中後三位數: 「雜六、半順、順子、豹子、對子」
// f: 前(default), m：中, l：後
export function MMThree (n, position = 'f') {
  var f = commonCodeNums(n, position)
  var sort = f.sort()

  var z = f.indexOf(0) !== -1 && f.indexOf(9) !== -1 && f.indexOf(8) !== -1
  var i = f.indexOf(0) !== -1 && f.indexOf(9) !== -1 && f.indexOf(1) !== -1
  var o = f.indexOf(0) !== -1 && f.indexOf(9) !== -1

  if (
    sort[0] === sort[1] ||
    sort[0] === sort[2] ||
    sort[1] === sort[2]
  ) {
    if (
      sort[0] === sort[1] &&
      sort[0] === sort[2]
    ) {
      return '豹子'
    }
    return '对子'
  } else {
    if (
      (
        sort[0] + 1 === sort[1] &&
        sort[1] + 1 === sort[2]
      ) || z || i
    ) {
      return '顺子'
    } else if (
      sort[0] + 1 === sort[1] ||
      sort[1] + 1 === sort[2] ||
      o
    ) {
      return '半顺'
    }
    return '杂六'
  }
}

// 龍虎和
export function MMDT (n) {
  const th = Number(n[0])
  const d = Number(n[n.length - 1])
  if (th > d) {
    return '龙'
  } else if (th < d) {
    return '虎'
  }
  return '和'
}
