import request from '@/utils/axios'

// 取得 新聞/玩法規則/贏錢技巧列表
export function getTricksList (params) {
  return request({
    url: '/api/info/winskill',
    method: 'get',
    params: params
  })
}

// 取得單一 新聞/玩法規則/贏錢技巧
export function getTrick (id) {
  return request({
    url: `/api/info/winskill/${id}`,
    method: 'get'
  })
}
